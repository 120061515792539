import React from "react";
import { graphql } from "gatsby";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { mapEdgesToNodes, toPlainText } from "../lib/helpers";
import { CardContext } from "../components/card/context";
import { suggest } from "../lib/suggestions";
import { ResourcesSuggestions, SectionWrapper } from "../components/sections";
import PortableText from "../components/portableText";
import { format } from "date-fns";
import { CardGrid } from "../components/card/card-grid";
import { pageDocPath } from "../../helpers";
import { SearchPopup } from "../components/sections/snowflake/search";
import { DocumentationMenu } from "../components/sections/snowflake/docs-menu";
import { ImageBuilder } from "../components/global/image-builder";

import * as styles from "../components/portableText.module.css";

export const query = graphql`
  query DocumentationTemplateQuery($id: String!, $integrations: [String!]) {
    doc: sanityDocumentation(id: { eq: $id }) {
      _type
      _id
      id
      _updatedAt
      menu
      menuParent
      categories {
        _id
        _type
        title
        titlePublic
        slug {
          current
        }
        status
        mainImage {
          ...SanityImage
          alt
        }
      }
      title
      slug {
        current
      }
      status
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 12 })
      integrations {
        ...Integration
      }
      suggestions {
        ... on SanityPost {
          ...cardNode
        }
        ... on SanityAsset {
          ...cardNodeAsset
        }
        ... on SanityPage {
          ...cardNodePage
        }
        ... on SanityPlaybook {
          ...cardNodePlaybook
        }
        ... on SanityDocumentation {
          ...cardNodeDocumentation
        }
      }
    }
    docs: allSanityDocumentation(
      sort: { fields: [orderRank] }
      filter: { menu: { ne: null }, status: { eq: "published" } }
    ) {
      edges {
        node {
          _type
          id
          _id
          menu
          menuParent
          title
          menuTitle
          slug {
            current
          }
          integrations {
            _id
          }
        }
      }
    }
    playbooks: allSanityPlaybook(
      limit: 3
      sort: { fields: [_createdAt], order: DESC }
      filter: { integrations: { elemMatch: { _id: { in: $integrations } } } }
    ) {
      edges {
        node {
          ...cardNodePlaybook
        }
      }
    }
    integrations: allSanityIntegration {
      edges {
        node {
          _id
          docRef {
            _id
          }
          mainImageSolid {
            ...SanityImageSimple
          }
          integrationsSimilar {
            company
            mainImageSolid {
              ...SanityImageSimple
            }
            docRef {
              _id
              _type
              slug {
                current
              }
              menu
              menuParent
            }
          }
          integrationsPairs {
            company
            mainImageSolid {
              ...SanityImageSimple
            }
            docRef {
              _id
              _type
              slug {
                current
              }
              menu
              menuParent
            }
          }
        }
      }
    }
  }
`;

const DocumentationTemplate = ({ data }) => {
  const docs = mapEdgesToNodes(data?.docs);

  return (
    <LayoutContainer doc={data?.doc}>
      <PageHeadMeta
        title={`${data?.doc.title} | Common Room (Docs)`}
        isTitleOverriden={true}
        description={toPlainText(data?.doc._rawExcerpt)}
        image={data?.doc.mainImage}
        doc={data?.doc}
      />
      {data?.doc && (
        <CardContext.Provider value={{ ...data?.doc }}>
          <DocumentationPage
            {...data?.doc}
            suggest={suggest(data)}
            docs={docs}
            playbooks={mapEdgesToNodes(data?.playbooks)}
            integration={mapEdgesToNodes(data?.integrations).find(
              (i) => i._id === data?.doc?.integrations?.[0]?._id
            )}
            relatedDocs={docs.filter(
              (d) =>
                data?.doc?.integrations?.[0]?._id &&
                d._id !== data?.doc?._id &&
                d.integrations?.[0]?._id === data?.doc?.integrations?.[0]?._id
            )}
          />
        </CardContext.Provider>
      )}
    </LayoutContainer>
  );
};

export default DocumentationTemplate;

const DocumentationPage = ({
  title,
  menu,
  _rawBody,
  categories,
  _updatedAt,
  docs,
  suggestions,
  suggest,
  playbooks,
  menuParent,
  integration,
  relatedDocs,
}) => {
  const relatedIntegrations = integration
    ? integration?.integrationsSimilar.concat(integration?.integrationsPairs)
    : [];

  return (
    <>
      <SectionWrapper padding="tight">
        <div className="grid relative xl:grid-cols-menu-content-toc">
          <div className="mb-10 mt-12">
            <div className="p-4 pb-12 bg-dark-5 rounded-2xl">
              <SearchPopup />
              <div className="mt-8">
                <DocumentationMenu docs={docs} menu={menu} />
              </div>
            </div>
          </div>
          <div className="mt-16 pt-2 mb-16 xl:mb-0 md:mx-12">
            <h1
              className="font-semibold tracking-tight flex items-center"
              style={{ fontSize: "1.5rem" }}
            >
              {integration && (
                <ImageBuilder
                  image={integration.mainImageSolid}
                  width={36}
                  className="mr-2 rounded-md"
                  alt={`${title} logo`}
                  quality={100}
                />
              )}
              {title}
            </h1>
            <p className="mt-2 mb-8 text-sm text-auxiliary">
              Last updated {format(new Date(_updatedAt), "MMM do, yyyy")}
            </p>
            <div className="leading-relaxed">
              {_rawBody && (
                <PortableText blocks={_rawBody} className={styles.docsBody} />
              )}
            </div>
            <div className="mt-12 mb-2 text-auxiliary">
              Didn't find your answer?
              <a
                href="/contact/"
                className="font-semibold tracking-tight hover:text-link mt-2 mx-1"
              >
                Get in touch
              </a>
              &middot; Visit our
              <a
                href="/help/"
                className="font-semibold tracking-tight hover:text-link mx-1"
              >
                help center
              </a>
            </div>
          </div>
          <div className="md:mt-8 hidden xl:block">
            <div
              className="sticky top-28 left-0 text-secondary"
              style={{ fontSize: 13 }}
            >
              {_rawBody &&
                _rawBody?.filter((b) => ["h2"].includes(b.style)).length >
                  2 && (
                  <div className="mb-8">
                    <a className="mb-2 font-semibold block" href="#top">
                      {/* <FontAwesomeIcon icon={faList} className="mr-2" /> */}
                      On this page
                    </a>
                    {_rawBody && <TOC body={_rawBody} />}
                  </div>
                )}
              {relatedDocs.length > 0 && (
                <div className="mb-8">
                  <h3 className="mt-6 mb-2 font-medium">Related Docs</h3>
                  <ul className="text-sm">
                    {relatedDocs.map((d, i) => (
                      <li key={i}>
                        <a
                          href={pageDocPath(d)}
                          className="ml-1 pl-3 py-2 border-l border-dark-10 hover:border-link hover:text-link block"
                        >
                          {d.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {playbooks.length > 0 && (
                <div className="mb-8">
                  <h3 className="mt-6 mb-2 font-medium">Related Playbooks</h3>
                  <ul className="text-sm">
                    {playbooks.map((p, i) => (
                      <li key={i}>
                        <a
                          href={pageDocPath(p)}
                          className="ml-1 pl-3 py-2 border-l border-dark-10 hover:border-link hover:text-link block"
                        >
                          {p.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <p className="text-sm mt-2">
                    <a href="/playbooks/" className="hover:text-link">
                      See all playbooks
                    </a>
                  </p>
                </div>
              )}
              {relatedIntegrations.length > 0 && (
                <>
                  <h3 className="mb-4 font-medium">Related Integrations</h3>
                  <ul className="flex ml-1 pl-3 border-l border-dark-10">
                    {relatedIntegrations.map((i, n) =>
                      i.docRef ? (
                        <li className="mr-2" key={n}>
                          <a
                            key={n}
                            href={pageDocPath(i.docRef)}
                            className="inline-block transform transition-size scale-100 hover:scale-110 group mr-0.75"
                            title={i.company}
                          >
                            <ImageBuilder
                              image={i.mainImageSolid}
                              width={28}
                              className="inline-block relative -top-px rounded-md saturate-0 group-hover:saturate-100"
                              alt={`${i.company} logo`}
                              quality={100}
                            />
                          </a>
                        </li>
                      ) : null
                    )}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </SectionWrapper>

      <div className="hidden xl:block">
        <SectionWrapper
          header="Fresh playbooks"
          layout="centered"
          container="large"
        >
          {suggestions.length > 0 ? (
            <CardGrid
              nodes={suggestions}
              panels={true}
              hideLabels={false}
              showSubTags={true}
              showExtended={true}
              browseMoreHref="/resources/"
              browseMoreText="See all resources"
              attribution={{ action: "suggestion", label: "playbook" }}
              maxColumns={3}
            />
          ) : (
            <ResourcesSuggestions section={{ cards: 6 }} maxColumns={3} />
          )}
        </SectionWrapper>
      </div>
    </>
  );
};

const TOC = ({ body }) => (
  <ul className="text-sm">
    {body
      .filter((b) => ["h2"].includes(b.style))
      .map((b, i) => (
        <li
          className="ml-1 last:pb-0 border-l border-dark-10 hover:border-link"
          key={i}
        >
          <a
            href={`#${tocId(b.children[0]?.text)}`}
            className="hover:text-link py-2 block ml-3"
          >
            {b.children[0].text}
          </a>
        </li>
      ))}
  </ul>
);

export const tocId = (text) =>
  typeof text === "string"
    ? text.toLowerCase().replace(/[\W_]+/g, "-")
    : text?.props
    ? text?.props.node.children[0].toLowerCase().replace(/[\W_]+/g, "-")
    : null;
