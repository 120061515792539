import React from "react";

export const CardContext = React.createContext({
  isInList: false,
  isFeatured: false,
  jumboLayout: false,
  slug: "",
  mainImage: "",
  cardImage: "",
  publishedAt: "",
  title: "",
  _rawExcerpt: [],
  showSummary: false,
  summary: null,
  showByline: false,
  authors: [],
  categories: [],
  duration: 60,
  mainVideo: false,
  signupOpen: true,
  externalUrl: "",
  sendgridListId: "",
  recurring: "",
  recurringPublic: "",
  hideMainMedia: false,
  hideLabels: false,
  showSubTags: false,
  hideImage: false,
  minimal: false,
  maxColumns: 0,
  _type: "",
  allowSummary: false,
  horizontalImage: true,
  hideFeatured: false,
  hideDate: false,
  showLogo: false,
  companies: [],
  isTall: true,
  readingTimeInMinutes: null,
  url: null,
  imageUrl: null,
  isVideo: false,
  showExtended: false,
  // used on spreadsheet videos
  tags: [],
  hideTitles: false,
  skill: null,
  attribution: {},
  panels: false,
  hideType: false,
  externalUrl: null,
  skipLink: false,
  sourceType: null,
  strength: null,
  partner: false,
  status: null,
  isMasonry: false,
  skipAuthorLink: false,
});
