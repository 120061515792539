import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { cn, toPlainText } from "../../../lib/helpers";
import { Button } from "../../button";
import ReactTooltip from "react-tooltip";
import { CheckMark } from "../../icon/check-mark";
import { SiteContext } from "../../global/site-context";

import * as styles from "./pricing-plans.module.css";

// @todo change structure to allow using main vars
const CONTACT_URL = "/contact/";
const CONTACT_PHRASE = "Contact us";
const ATTRIBUTION_PARAM = "website_attribution";
// duplicated here because of static object use below
const SIGNUP_URL = "/signup/";
const SIGNUP_PHRASE = "Start for free";

// @todo change Button.bgColor to be the pure color vs class
export const ACTIONS = {
  essentials: {
    text: SIGNUP_PHRASE,
    bgColor: "bg-dark-2",
    className: "border-black",
    color: "transparent",
    href: `${SIGNUP_URL}?${ATTRIBUTION_PARAM}=cta-essentials--pricing`,
    integrations: "essentials",
  },
  start: {
    text: SIGNUP_PHRASE,
    bgColor: "bg-lemon",
    color: "lemon",
    href: `${SIGNUP_URL}?${ATTRIBUTION_PARAM}=cta-starter--pricing`,
    openSource: true,
    integrations: "starter",
  },
  team: {
    text: SIGNUP_PHRASE,
    bgColor: "bg-mint",
    color: "mint",
    href: `${SIGNUP_URL}?${ATTRIBUTION_PARAM}=cta-team--pricing`,
    openSource: true,
    integrations: "team",
  },
  enterprise: {
    text: CONTACT_PHRASE,
    bgColor: "bg-lavender",
    className: "border border-black",
    color: "lavender",
    href: `${CONTACT_URL}?${ATTRIBUTION_PARAM}=cta-enterprise--pricing`,
    integrations: "enterprise",
  },
};

export const PricingPlans = ({ section }) => {
  const site = useContext(SiteContext);
  const { pricing } =
    useStaticQuery(
      graphql`
        query PricingPlansQuery {
          pricing: sanityPricingWrapper(_id: { eq: "PricingTiers" }) {
            panels {
              _key
              title
              price
              priceDetails
              _rawSummary(resolveReferences: { maxDepth: 5 })
              action
              actionDetails
              details {
                _key
                title
                path
                _rawSummary(resolveReferences: { maxDepth: 5 })
              }
              integrations
            }
          }
        }
      `
    ) || {};

  return (
    <>
      <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-12 sm:gap-2 md:gap-10 lg:gap-2">
        {pricing?.panels.slice(0, 4).map((p, i) => (
          <TierPanel panel={{ ...p, action: ACTIONS[p.action] }} key={i} />
        ))}
      </div>
      <p className="text-center mt-6 text-lg">
        {site.token("pricingPlansSummary")}
      </p>
    </>
  );
};

export const PlanPill = ({ title, color }) => (
  <span
    className={cn(
      "inline-block font-semibold px-2 py-1 rounded-lg border-2 border-dark-10",
      `bg-${color}`
    )}
  >
    {title}
  </span>
);

const TierPanel = ({ panel }) => (
  <div className="rounded-3xl border border-dark-10 bg-white p-6 pt-8 text-sm">
    <div className="lg:px-3 flex flex-col h-full">
      <div>
        <PlanPill title={panel.title} color={panel?.action?.color} />
      </div>
      <div className="pt-4 md:h-32 lg:h-28">
        <p className="text-secondary">
          {["team", "starter"].includes(panel.action.integrations) ? (
            <>Starting at</>
          ) : (
            <>&nbsp;</>
          )}
        </p>
        <p className="text-3xl sm:text-2xl md:text-3xl lg:text-4xl font-bold">
          {panel.price}
        </p>
        <p className="leading-tight pb-4 text-secondary">
          {panel.priceDetails} {panel.actionDetails}
        </p>
      </div>
      <PanelDetails panel={panel} />
      <div className="my-4 text-center flex-grow flex items-end">
        <Button
          isBig={true}
          isJumbo={true}
          {...panel.action}
          className="w-full"
          metrics={{ action: `pricing-${panel.integrations}` }}
        />
      </div>
    </div>
  </div>
);

const PanelDetails = ({ panel }) =>
  panel.details ? (
    <ul>
      {panel.details.map((detail, i) =>
        detail.title ? (
          <li
            className="mb-4 leading-normal grid"
            style={{
              gridTemplateColumns: "2rem auto",
              // minHeight: "2.75rem",
            }}
            key={detail._key}
          >
            <CheckMark color={panel.action.color} />
            <div className="flex items-start mt-1 leading-tight">
              {detail.path ? (
                <a
                  href={detail.path}
                  className="text-link hover:underline"
                  dangerouslySetInnerHTML={{
                    __html: detail.title.replace("  ", "<br />"),
                  }}
                ></a>
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: detail.title.replace("  ", "<br />"),
                  }}
                ></p>
              )}
              {detail._rawSummary && (
                <>
                  <div
                    className="ml-2 mt-px text-secondary top-3"
                    data-tip={
                      detail._rawSummary
                        ? toPlainText(detail._rawSummary)
                        : null
                    }
                    data-for={detail._key}
                  >
                    <InfoIcon />
                  </div>
                  <ReactTooltip
                    effect="solid"
                    color="white"
                    backgroundColor="#333333"
                    className={styles.toolTip}
                    offset={{ top: -5 }}
                    id={detail._key}
                  />
                </>
              )}
            </div>
          </li>
        ) : (
          <li
            className="mb-4 pl-9 leading-normal grid justify-items-start"
            key={i}
          >
            <hr className="block h-1 w-1/3 rounded-full bg-dark-10" />
          </li>
        )
      )}
    </ul>
  ) : null;

export const InfoIcon = ({ className }) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7 0.75C3.11719 0.75 0 3.89453 0 7.75C0 11.6328 3.11719 14.75 7 14.75C10.8555 14.75 14 11.6328 14 7.75C14 3.89453 10.8555 0.75 7 0.75ZM7 13.4375C3.85547 13.4375 1.3125 10.8945 1.3125 7.75C1.3125 4.63281 3.85547 2.0625 7 2.0625C10.1172 2.0625 12.6875 4.63281 12.6875 7.75C12.6875 10.8945 10.1172 13.4375 7 13.4375ZM8.09375 9.9375H7.65625V7.53125C7.65625 7.17578 7.35547 6.875 7 6.875H6.125C5.74219 6.875 5.46875 7.17578 5.46875 7.53125C5.46875 7.91406 5.74219 8.1875 6.125 8.1875H6.34375V9.9375H5.90625C5.52344 9.9375 5.25 10.2383 5.25 10.5938C5.25 10.9766 5.52344 11.25 5.90625 11.25H8.09375C8.44922 11.25 8.75 10.9766 8.75 10.5938C8.75 10.2383 8.44922 9.9375 8.09375 9.9375ZM7 6C7.46484 6 7.875 5.61719 7.875 5.125C7.875 4.66016 7.46484 4.25 7 4.25C6.50781 4.25 6.125 4.66016 6.125 5.125C6.125 5.61719 6.50781 6 7 6Z"
      fill="currentColor"
    />
  </svg>
);
