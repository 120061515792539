import React, { useContext, useEffect, useState } from "react";
import { cn, mapEdgesToNodes } from "../../lib/helpers";
import { graphql, Link, useStaticQuery } from "gatsby";
import { CardGrid } from "../card/card-grid";
import { pageDocPath } from "../../../helpers";
import { SiteContext } from "../global/site-context";
import { SearchPopup } from "./snowflake/search";
import { ArrowLeft } from "../icon/arrow-left";
import { ImageBuilder } from "../global/image-builder";
// import { Search } from "./search";

const DEFAULT_LIMIT = 144;

const MENU = [
  {
    label: "All",
    slug: "",
  },
  {
    label: "Videos",
    slug: "videos",
    type: true,
  },
  {
    label: "Guides",
    slug: "guides",
    type: true,
  },
  {
    label: "Playbooks",
    slug: "playbooks",
    type: true,
  },
  {
    label: "Blog",
    slug: "blog",
    type: true,
  },
  // {
  //   divider: true,
  // },
  {
    label: "Sales & Marketing",
    slug: "go-to-market",
    bulkHeader: "gtm",
    emoji: "emoji-arrow-up-right.svg",
  },
  {
    label: "Community teams",
    slug: "community-teams",
    bulkHeader: "community",
    emoji: "emoji-peeps.svg",
  },
  {
    label: "DevRel",
    slug: "community/developer-relations",
    bulkHeader: "devRel",
    emoji: "emoji-avocado-vertical.svg",
  },
];

const query = graphql`
  query ResourcesListQuery {
    nonVideoPosts: allSanityPost(
      limit: 72
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        status: { eq: "published" }
        mainVideo: { url: { eq: null } }
        categories: {
          elemMatch: {
            _id: {
              in: [
                "086bd107-8028-4911-8060-ba5a88f93d5a" # product
              ]
            }
          }
        }
      }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
    videoPosts: allSanityPost(
      limit: 72
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        status: { eq: "published" }
        mainVideo: { url: { ne: null } }
      }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
    assets: allSanityAsset(
      limit: 72
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        # keep these on two lines, linter breaks the query
        slug: { current: { ne: null } }
        status: { eq: "published" }
      }
    ) {
      edges {
        node {
          ...cardNodeAsset
        }
      }
    }
    playbooks: allSanityPlaybook(
      limit: 72
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        # keep these on two lines, linter breaks the query
        slug: { current: { ne: null } }
        status: { eq: "published" }
      }
    ) {
      edges {
        node {
          ...cardNodePlaybook
        }
      }
    }
    pages: allSanityPage(
      limit: 72
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        status: { eq: "published" }
        contentType: { in: ["video", "guide", "report", "playbook"] }
      }
    ) {
      edges {
        node {
          ...cardNodePage
        }
      }
    }
    videoPages: allSanityVideo(
      filter: { slug: { current: { ne: null } }, status: { eq: "published" } }
    ) {
      edges {
        node {
          ...cardNodeVideo
        }
      }
    }
    videos: allGoogleSpreadsheetWwWebsiteContentVideos(
      sort: { fields: [publishDate], order: DESC }
      filter: { status: { eq: "published" } }
    ) {
      edges {
        node {
          title
          videoId
          playlist
          slug
          community
          devRel
          gtm
          publishDate
        }
      }
    }
  }
`;

const typesMap = new Map([
  [
    "post",
    {
      label: "blog posts",
      path: "/blog/",
    },
  ],
  [
    "asset",
    {
      label: "guides",
      path: "/guides/",
    },
  ],
  [
    "video",
    {
      path: "/resources/?filters=videos",
    },
  ],
  [
    "asset",
    {
      path: "/resources/?filters=guides",
    },
  ],
]);

export const ResourcesList = ({ section, hideFilters }) => {
  const site = useContext(SiteContext);

  const data = useStaticQuery(query) || {};
  const posts =
    data && data.nonVideoPosts && mapEdgesToNodes(data.nonVideoPosts);
  const videoPosts =
    data && data.videoPosts && mapEdgesToNodes(data.videoPosts);
  const assets = data && data.assets && mapEdgesToNodes(data.assets);
  const videoPages =
    data && data.videoPages && mapEdgesToNodes(data.videoPages);
  const videos = data && data.videos && mapEdgesToNodes(data.videos);
  const pages = data && data.pages && mapEdgesToNodes(data.pages);
  const playbooks = data && data.playbooks && mapEdgesToNodes(data.playbooks);

  // @todo move publishedAt vs _createdAt into <PublishDate />

  const unique = [
    ...new Map(
      assets
        .map((a) => {
          return { ...a, publishedAt: a.publishedAt || a._createdAt };
        })
        .concat(
          pages.map((a) => {
            return {
              ...a,
              publishedAt: a._createdAt,
              isVideo: a?.contentType === "video",
            };
          })
        )
        // .concat(
        //   playbooks.map((p) => {
        //     return { ...p, publishedAt: p._createdAt };
        //   })
        // )
        .concat(
          playbooks.map((p) => {
            return { ...p, publishedAt: p._createdAt };
          })
        )
        .concat(
          videoPosts.map((a) => {
            return { ...a, isVideo: true };
          })
        )
        .concat(
          videoPages.map((a) => {
            return { ...a, isVideo: true };
          })
        )
        .concat(posts)
        .concat(
          videos.map((v) => {
            return {
              ...v,
              imageUrl: `https://img.youtube.com/vi/${v.videoId}/maxresdefault.jpg`,
              url: pageDocPath({ ...v, _type: "video" }),
              _type: "video",
              isVideo: true,
              publishedAt: v.publishDate,
              tags: ["community", "devRel", "gtm"].filter((t) => v[t] === "1"),
            };
          })
        )
        // .concat(topics)
        .map((p) => [p.id || p.url, p])
    ).values(),
  ].sort((a, b) => {
    return (a.publishedAt || a._createdAt) < (b.publishedAt || b._createdAt)
      ? 1
      : -1;
  });

  const allNodes =
    section?.types && section.types.length > 0
      ? unique.filter((n) => section.types.includes(n._type))
      : unique;
  const [nodes, setNodes] = useState(allNodes);

  const shortList = !!section.cards && section.cards <= 6;

  return (
    <>
      {/* <Search /> */}
      {!hideFilters && (
        <Filters
          nodes={allNodes}
          setNodes={setNodes}
          hideTypeFilters={shortList}
        />
      )}
      {nodes.length ? (
        <CardGrid
          maxColumns={3}
          nodes={nodes.slice(0, section.cards || DEFAULT_LIMIT)}
          hideEvents={true}
          makeThumbnail={true}
          hideLabels={false}
          showSubTags={true}
          showExtended={true}
          hideTitles={true}
        />
      ) : (
        <p className="text-center mt-24">Whoops. Please let us know.</p>
      )}
      {shortList && (
        <div className="flex justify-center mt-12 text-link font-medium">
          {section.types > 1 ? (
            <a href="/resources/" className="hover:underline">
              See all resources
            </a>
          ) : (
            <a
              href={
                typesMap.get(section.types[0])?.path || `/${section.types[0]}s/`
              }
              className="hover:underline"
            >
              See all{" "}
              {typesMap.get(section.types[0])?.label || `${section.types[0]}s`}
              <ArrowLeft className="ml-1" rotate={180} />
            </a>
          )}
        </div>
      )}
    </>
  );
};

const Filters = ({ nodes, setNodes, hideTypeFilters }) => {
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    setFilters(urlParams.get("filters")?.toLowerCase().split(",") || []);
  }, []);

  useEffect(() => {
    setNodes(
      !filters
        ? nodes
        : nodes.filter((n) => {
            return filterNode(n, filters);
          })
    );
  }, [filters]);

  return (
    <ul className="flex flex-wrap justify-center mb-12">
      {MENU.map((m, i) =>
        !m.hidden && (!hideTypeFilters || (hideTypeFilters && !m.type)) ? (
          !m.divider ? (
            <li className="mr-3 font-medium last:mr-0" key={i}>
              <Link
                to={`/resources/?filters=${
                  m.slug === ""
                    ? []
                    : !filters
                    ? m.slug
                    : filters.includes(m.slug)
                    ? filters.filter((f) => f !== m.slug).join(",")
                    : filters.concat([m.slug]).join(",")
                }`}
                className={cn(
                  "py-2 px-3 rounded-lg inline-block my-2 flex items-center",
                  (m.slug !== "" && filters && filters.includes(m.slug)) ||
                    ((filters.length === 0 ||
                      (filters.length === 1 && filters[0] === "")) &&
                      m.slug === "")
                    ? "text-white bg-dark-90"
                    : "bg-dark-5 hover:bg-dark-10"
                )}
                onClick={(e) => {
                  e.preventDefault();
                  const query =
                    m.slug === ""
                      ? []
                      : !filters
                      ? m.slug
                      : filters.includes(m.slug)
                      ? filters.filter((f) => f !== m.slug)
                      : filters.concat([m.slug]);
                  setFilters(query);
                  window.history.pushState(
                    {},
                    "",
                    `?filters=${query.join(",")}`
                  );
                }}
              >
                {m.emoji && (
                  <ImageBuilder
                    src={`/static/img/${m.emoji}`}
                    className="h-4 mr-1"
                  />
                )}
                {m.label}
              </Link>
            </li>
          ) : (
            <li className="flex justify-center items-center">
              <div className="ml-3 mr-6 rounded-full bg-dark-30 w-2 h-2"></div>
            </li>
          )
        ) : null
      )}
      {/* <li>
        <SearchPopup iconOnly={true} results="resources" />
      </li> */}
    </ul>
  );
};

/**
 * (TYPE OR TYPE) AND (PERSONA OR PERSONA)
 *
 * @param {*} node
 * @param {[string]} filters
 *
 * @returns {[{*}]}
 */
const TYPES = ["videos", "blog", "guides", "playbooks"];
const filterNode = (node, filters = []) => {
  const hasType = !!filters.find((f) => TYPES.includes(f));
  const hasTag = !!filters.find((f) => !TYPES.includes(f));

  const typeMatch =
    (filters.includes("videos") && node.isVideo) ||
    (filters.includes("blog") && node._type === "post") ||
    (filters.includes("playbooks") && node._type === "playbook") ||
    (filters.includes("guides") &&
      (node._type === "asset" ||
        (node._type === "page" &&
          (node?.contentType === "guide" || node?.contentType === "report"))));

  const tagMatch = matchCategories(
    node,
    filters.filter((f) => !TYPES.includes(f))
  );

  if (hasType && hasTag) {
    return typeMatch && tagMatch;
  } else if (hasType) {
    return typeMatch;
  } else if (hasTag) {
    return tagMatch;
  }
  return true;
};

// note: bulkHeader is used in the google doc data source
export const matchCategories = (doc, filters) =>
  filters.find(
    (f) =>
      doc.categories?.find((c) => c.slug.current === f) ||
      doc?.[MENU.find((m) => m.slug === f)?.bulkHeader] == 1
  );
