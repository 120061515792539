import { navigate } from "gatsby";
import React from "react";
import { useInView } from "react-intersection-observer";
import { cn, currentPath } from "../../lib/helpers";
import { ArrowElbow } from "../icon/arrow-elbow";
import { List } from "../icon/list";

import * as styles from "../web-form-field.module.css";
import { SearchPopup } from "./snowflake/search";

// @todo change to flat menu list structure with "indent" option
// @todo share with configs in pills.js

const filterList = [
  {
    label: "Latest",
    url: "/blog",
  },
  {
    label: "Product",
    url: "/topics/product",
    waiting: true,
    color: "blue-400",
  },
  {
    label: "Customer stories",
    url: "/topics/customers/",
    waiting: true,
    color: "green-400",
  },
  {
    label: "Uncommon community",
    url: "/topics/community",
    color: "blue-100",
    options: [
      {
        label: "Community spotlight",
        url: "/topics/community/spotlight",
      },
      {
        label: "DevRel",
        url: "/topics/community/developer-relations",
      },
      {
        label: "Best practices",
        url: "/topics/community/best-practices",
      },
    ],
  },
  {
    label: "Company news",
    url: "/topics/company-news",
    color: "orange-800",
  },
  {
    label: "Archive",
    url: "/blog/archive",
  },
];

const pillClasses =
  "inline-block mb-4 hover:text-link hover:underline flex gap-2 items-center";

export const Dot = ({ color }) => (
  <div
    className={cn(
      "rounded-full border w-4 h-4 relative overflow-hidden",
      `border-${color || "dark-30"}`
    )}
  >
    <div
      className={cn(
        "absolute top-0 left-0 w-full h-full rounded-full opacity-10",
        `bg-${color}`
      )}
    ></div>
  </div>
);

export const Filters = () => {
  const { ref, inView } = useInView({
    initialInView: true,
  });
  const path = `/${currentPath()}`;

  return (
    <>
      <div ref={ref}></div>
      <div className="pt-6 xl:pt-0 xl:hidden max-w-screen-sm mx-auto px-4">
        <SelectMenu menu={filterList} />
      </div>
      <div
        className={cn(
          "hidden xl:block",
          "z-40 left-0 top-24 pt-8 pl-12 text-base",
          inView ? "absolute" : "fixed"
        )}
      >
        <ul>
          {filterList.map((f) => {
            return f.options ? (
              <li key={f.label}>
                <a
                  href={f.url}
                  className={cn(
                    pillClasses,
                    path === f.url ? "font-semibold" : ""
                  )}
                >
                  <Dot color={f.color} />
                  {f.label}
                </a>
                {f.options && (
                  <ul>
                    {f.options.map((sub, i) => (
                      <li key={i}>
                        <a
                          href={sub.url}
                          className={cn(
                            pillClasses,
                            path === sub.url ? "font-semibold border-link" : "",
                            "ml-2 flex gap-2 items-center"
                          )}
                        >
                          <span className="text-black">
                            <ArrowElbow />
                          </span>
                          {sub.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ) : (
              <li key={f.label}>
                <a
                  href={f.url}
                  className={cn(
                    pillClasses,
                    path === f.url ? "font-semibold" : ""
                  )}
                >
                  {f.color ? (
                    <Dot color={f.color} />
                  ) : (
                    <div className="w-4"></div>
                  )}
                  {f.label}
                </a>
              </li>
            );
          })}
          {/* <li className="ml-5">
            <SearchPopup iconOnly={true} results="post" />
          </li> */}
        </ul>
        {/* <div className="p-2.5 px-3.5 rounded-2xl border border-dark-10">
          <Subscribe
            section={{
              attribution: { campaignType: "filters" },
              buttonText: "Subscribe",
              buttonClasses: "bg-dark-30 hover:bg-link text-black",
            }}
          />
        </div> */}
      </div>
    </>
  );
};

export const SelectMenu = ({ menu, label }) => (
  <div
    className={cn(
      styles.selectWrapper,
      "flex gap-2 items-center p-2.5 px-3.5 rounded-2xl",
      "bg-white focus:text-black border border-dark-10"
    )}
  >
    <List size={20} />
    <select
      className="w-full appearance-none focus:ring-0 outline-none bg-white"
      onChange={(e) => {
        if (e.target.value !== "none") {
          navigate(e.target.value);
        }
      }}
    >
      <option>{label || "Categories"}</option>
      {menu.map((tag) => {
        if (tag.options) {
          return (
            <React.Fragment key={tag.label}>
              <option value={tag.url}>{tag.label}</option>;
              {tag.options.map((opt) => (
                <option value={opt.url} key={opt.label}>
                  - {opt.label}
                </option>
              ))}
            </React.Fragment>
          );
        }
        return (
          <option value={tag.url} key={tag.label}>
            {tag.label}
          </option>
        );
      })}
    </select>
  </div>
);
