import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { CardGrid } from "../../card/card-grid";

// Tag structure doc
// https://www.notion.so/commonroomnyc/Blog-categories-13801b2167114a6ca0df484bf9a6038e

const query = graphql`
  query TopicSpotlightQuery {
    docs: allSanityPost(
      limit: 36
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        isPublished: { eq: true }
        categories: {
          elemMatch: {
            _id: {
              in: [
                "da38ee48-4ccb-4632-aed4-fae6aeb77b8c" # convos
                "f9701e5b-3434-4c8d-9280-52b2a1dfea87" # rise
                "671866fa-3131-49ce-8be3-e4936cc150c9" # book club
                "5abeceb9-dd3e-4e79-8abc-05a81ac41bd9" # curated
                "361e2200-dc24-4ab1-b465-081150a7464d" # comm-driven
              ]
            }
          }
        }
      }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
  }
`;

export const TopicSpotlight = () => {
  const { docs } = useStaticQuery(query) || {};

  return docs ? (
    <CardGrid
      maxColumns={1}
      nodes={mapEdgesToNodes(docs)}
      browseMoreHref="/blog"
      hideType={true}
      showSubTags={true}
    />
  ) : null;
};
