import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { CardGrid } from "../../card/card-grid";

const query = graphql`
  query TopicCustomersQuery {
    docs: allSanityStory(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, status: { eq: "published" } }
    ) {
      edges {
        node {
          ...cardNodeStory
        }
      }
    }
  }
`;

export const TopicCustomers = () => {
  const { docs } = useStaticQuery(query) || {};

  return docs ? (
    <CardGrid
      maxColumns={1}
      nodes={mapEdgesToNodes(docs)}
      browseMoreHref="/customers/"
      hideType={true}
      showSubTags={true}
    />
  ) : null;
};
