const EVENT_DATE_URL_CUTOFF = "2/10/2023";

// see: web/helpers-new.js

/**
 * Generate a path from the document with a slug.
 * @param {*} param0
 * @returns {string}
 */
module.exports.pageDocPath = ({
  _type = "",
  slug = {},
  startDate,
  recurring,
  customPath,
  company,
  videoId,
  prospect,
  menu,
  menuParent,
}) => {
  const uncommonPrefix = "/uncommon";
  const type = videoId ? "video" : _type;
  let date;
  let useDate;

  switch (type) {
    // commom room only
    case "post":
      return `/blog/${slug.current}/`;

    case "prospect":
      return `/hello/${slug.current}/`;

    case "asset":
      return `/resources/${slug.current}/`;

    case "playbook":
      return `/playbooks/${slug.current}/`;

    case "documentation":
      return `/docs/${module.exports.getArticlePath({
        parentId: menu,
        slug: slug.current,
        menuParent,
      })}`;

    case "company":
      return `/hello/${slug.current}/`;

    case "story":
      return `/customers/${slug.current}/`;

    case "signal":
      return `/resources/signals/${slug.current}/`;

    case "integration":
      return `/integrations/${module.exports.textToSlug(
        company.toLowerCase()
      )}/`;

    // default Uncommon
    case "event":
      date = new Date(startDate);
      useDate = !recurring && new Date(EVENT_DATE_URL_CUTOFF) - date > 0;

      return `${uncommonPrefix}/events/${
        useDate ? `${module.exports.formatDate(date)}--` : ""
      }${slug.current}/`;

    // default CR
    case "page":
      return `/${slug.current === "/" ? "" : `${slug.current}/`}`;

    case "category":
      return customPath ? `/${slug.current}/` : `/topics/${slug.current}/`;

    case "video":
      return videoId ? `/videos/${slug}/` : `/videos/${slug.current}/`;

    case "redirect":
      return slug.current;

    default:
      return slug.current || "";
  }
};

/**
 * Genreates the path for a documentation article.
 * @param {{ parentId: string, slug: string, menuParent: boolean }} param0
 * @returns string
 */
module.exports.getArticlePath = ({ parentId, slug, menuParent }) => {
  const path = [];
  let currentItem = module.exports.DOCS_MENU.find(
    (item) => item.id === parentId
  );

  while (currentItem) {
    path.unshift(currentItem.slug || currentItem.id);
    if (!currentItem.parent) break;
    currentItem = module.exports.DOCS_MENU.find(
      (item) => item.id === currentItem.parent
    );
  }

  return path.length ? `${path.join("/")}/${menuParent ? "" : `${slug}/`}` : "";
};

/**
 * Quick date formatter for urls.
 * @param {Date} date
 * @returns {string}
 */
module.exports.formatDate = (date) =>
  `${date.getMonth().toString().padStart(2, "0")}-${date
    .getDate()
    .toString()
    .padStart(2, "0")}-${date.getFullYear()}`;

/**
 * Clean path for tracking.
 * @param {*} doc
 * @returns {string}
 */
module.exports.trackPath = (doc) => {
  const path = module.exports.pageDocPath(doc);
  return encodeURIComponent(
    path === "/" ? "homepage" : path.replace(/^\/|\/$/g, "")
  );
};

module.exports.makeFilterDate = (d = new Date()) => {
  const month = `${d.getMonth() + 1}`.padStart(2, "0");
  const day = `${d.getDate()}`.padStart(2, "0");

  return `${d.getFullYear()}-${month}-${day}`;
};

/**
 * @param {{_type: string, children?: {text: string}[]}[] | null} blocks
 * @returns {string}
 */
module.exports.toPlainText = (blocks) =>
  blocks
    ? blocks
        .map((block) =>
          block._type !== "block" || !block.children
            ? ""
            : block.children.map((child) => child.text).join("")
        )
        .join("  ")
    : "";

/**
 * @param {string} text
 * @returns {string}
 */
module.exports.textToSlug = (text = "") => {
  return text.toLowerCase().replace(/\s+/g, "-");
};

// see src/lib/docs-menu-flat.js
module.exports.DOCS_MENU = [
  {
    title: "Welcome to Common Room",
    id: "get-started",
    path: "/docs/get-started/",
    parent: false,
    expanded: true,
  },
  {
    title: "Core Concepts",
    id: "core-concepts",
    parent: "get-started",
  },
  {
    title: "Signals and Integrations",
    id: "signals",
    path: "/docs/signals/",
    parent: false,
  },
  {
    title: "Common Room Native Signals",
    id: "common-room-native-signals",
    parent: "signals",
  },
  {
    title: "Business Data",
    id: "business-integrations",
    parent: "signals",
  },
  // {
  //   title: "Okta",
  //   id: "okta",
  //   parent: "business-integrations",
  // },
  // {
  //   title: "Salesforce Managed Package",
  //   id: "salesforce-managed-package",
  //   parent: "business-integrations",
  // },
  {
    title: "Product Usage",
    id: "product-usage-integrations",
    parent: "signals",
  },
  // {
  //   title: "S3",
  //   id: "s3",
  //   parent: "product-usage-integrations",
  // },
  {
    title: "Community and Social",
    id: "community-and-social-integrations",
    parent: "signals",
  },
  // {
  //   title: "Discourse",
  //   id: "discourse",
  //   parent: "community-and-social-integrations",
  // },
  // {
  //   title: "Slack",
  //   id: "slack",
  //   parent: "community-and-social-integrations",
  // },
  // {
  //   title: "YouTube",
  //   id: "youtube",
  //   parent: "community-and-social-integrations",
  // },
  {
    title: "Custom",
    id: "custom-integrations",
    parent: "signals",
  },
  // {
  //   title: "Zapier / API",
  //   id: "zapier-api",
  //   parent: "custom-integrations",
  // },
  {
    title: "Using Common Room",
    id: "using-common-room",
    path: "/docs/using-common-room/",
    parent: false,
  },
  {
    title: "Segments",
    id: "segments",
    parent: "using-common-room",
  },
  // {
  //   title: "Segment Actions",
  //   id: "segment-actions",
  //   parent: "segments",
  // },
  // {
  //   title: "Segment management",
  //   id: "segment-management",
  //   parent: "segments",
  // },
  {
    title: "Workflows",
    id: "workflows",
    parent: "using-common-room",
  },
  {
    title: "Contacts",
    id: "contacts",
    parent: "using-common-room",
  },
  {
    title: "Activity",
    id: "activity",
    slug: "activity-page",
    parent: "using-common-room",
  },
  {
    title: "Organizations",
    id: "orgs",
    slug: "organizations-page",
    parent: "using-common-room",
  },
  {
    title: "Reporting",
    id: "reporting",
    slug: "reporting-page",
    parent: "using-common-room",
  },
  {
    title: "Website Visitor Tracking",
    id: "visitor-tracking",
    slug: "website-visitor-tracking",
    parent: "using-common-room",
  },
  {
    title: "Set Preferences",
    id: "set-preferences",
    path: "/docs/set-preferences/account/",
    parent: false,
  },
  {
    title: "Find Help",
    id: "help",
    parent: false,
    hideCard: true,
  },
];
