import React from "react";
import { cn } from "../lib/helpers";
import { ImageBuilder } from "./global/image-builder";

const DATA_SOURCES = [
  {
    header: "1st-party",
    list: [
      {
        company: "Airmeet",
        anchor: "airmeet",
        url: "https://www.airmeet.com/",
      },
      { company: "Aligned", anchor: "aligned", url: "https://alignedup.com/" },
      {
        company: "Census",
        anchor: "census",
        url: "https://www.getcensus.com/",
      },
      {
        company: "Hightouch",
        anchor: "hightouch",
        url: "https://hightouch.com/",
      },
      {
        company: "Koala",
        anchor: "koala",
        url: "https://getkoala.com/",
      },
      {
        company: "Toplyne",
        anchor: "toplyne",
        url: "https://www.toplyne.io/",
      },
      {
        company: "Warmly",
        anchor: "warmly",
        url: "https://warmly.ai/",
      },
    ],
  },
  {
    header: "2nd-party",
    list: [
      {
        company: "Bevy",
        anchor: "bevy",
        url: "https://www.bevy.com/",
      },
      {
        company: "Bitergia",
        anchor: "bitergia",
        url: "https://bitergia.com/",
      },
      {
        company: "DEV",
        anchor: "dev",
        url: "https://dev.to/",
      },
      {
        company: "Discourse",
        anchor: "discourse",
        url: "https://www.discourse.org/",
      },
      {
        company: "Gradual",
        anchor: "gradual",
        url: "https://www.gradual.com/",
      },
      {
        company: "Scarf",
        anchor: "scarf",
        url: "https://about.scarf.sh/",
      },
    ],
  },
  {
    header: "3rd-party",
    list: [
      {
        company: "Clay",
        anchor: "clay",
        url: "https://www.clay.com/",
      },
      {
        company: "Keyplay",
        anchor: "keyplay",
        url: "https://keyplay.io/",
      },
      {
        company: "RB2B",
        anchor: "rb2b",
        url: "https://www.rb2b.com/",
      },
      {
        company: "Warmly",
        anchor: "warmly",
        url: "https://warmly.ai/",
      },
    ],
  },
];

export const CustomBlocks = ({ node }) => (
  <div className="leading-tight flex w-full mb-6">
    {DATA_SOURCES.map((n, i) => (
      <div
        key={n.header}
        className={cn("flex-grow", i > 0 ? "border-l border-dark-10" : null)}
      >
        <div className="text-sm md:text-base font-semibold bg-purple-default p-4 block">
          {n.header}
        </div>
        {n.list.map((link, i) => (
          <div className="p-2" key={link.company}>
            {
              <a href={`#${link.anchor}`} className="flex justify-center">
                <ImageBuilder
                  src={`/static/img/logos/signals/${link.anchor}-signal-partner.png`}
                  height={28}
                  className="mr-1 transform transition-card scale-100 hover:scale-105"
                  style={{ maxHeight: "80px" }}
                  alt={`${link.company} logo`}
                />
              </a>
            }
          </div>
        ))}
      </div>
    ))}
  </div>
);
