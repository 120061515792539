import React, { useContext } from "react";
import { ArrowLeft } from "./icon/arrow-left";
import { SectionContext } from "./sections/context";
import tailwindConfig from "../../tailwind.config";

export const BackToZone = ({ to, text }) => {
  const section = useContext(SectionContext);

  return (
    <a
      href={to}
      className="inline-flex items-center text-sm pb-10 font-semibold text-link hover:underline"
    >
      <ArrowLeft
        className="mr-2"
        color={section.isDarkBg ? "#FFF" : tailwindConfig.theme.colors.link}
      />
      {text}
    </a>
  );
};
