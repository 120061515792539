import React, { useContext } from "react";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { CardGrid } from "../../card/card-grid";
import { SiteContext } from "../../global/site-context";

const query = graphql`
  query BlogListQuery {
    posts: allSanityPost(
      limit: 24
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, status: { eq: "published" } }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
    stories: allSanityStory(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, status: { eq: "published" } }
    ) {
      edges {
        node {
          ...cardNodeStory
        }
      }
    }
  }
`;

export const BlogList = () => {
  const site = useContext(SiteContext);
  const data = useStaticQuery(query) || {};
  const posts = data?.posts && mapEdgesToNodes(data.posts);
  const stories = data?.stories && mapEdgesToNodes(data.stories);

  return (
    <CardGrid
      maxColumns={1}
      nodes={
        site.isActive("storiesInBlog")
          ? posts
              .concat(stories)
              .sort((a, b) => (a.publishedAt < b.publishedAt ? 1 : -1))
          : posts
      }
      browseMoreHref="/blog/archive/"
      hideType={true}
      showSubTags={true}
    />
  );
};
