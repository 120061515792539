import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { CardGrid } from "../../card/card-grid";

// Tag structure doc
// https://www.notion.so/commonroomnyc/Blog-categories-13801b2167114a6ca0df484bf9a6038e

// topic-community.js
// uncommon-teaser.js

const query = graphql`
  query TopicCommunityQuery {
    posts: allSanityPost(
      limit: 36
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        isPublished: { eq: true }
        categories: {
          elemMatch: {
            _id: {
              in: [
                "da38ee48-4ccb-4632-aed4-fae6aeb77b8c" # convos
                "f9701e5b-3434-4c8d-9280-52b2a1dfea87" # rise
                "671866fa-3131-49ce-8be3-e4936cc150c9" # book club
                "5abeceb9-dd3e-4e79-8abc-05a81ac41bd9" # curated
                "361e2200-dc24-4ab1-b465-081150a7464d" # comm-driven
                "acebef1a-78bc-489a-b970-1760f1c33d34" # devrel
                "62c8d09b-665d-4ecb-bc7b-e6769e14963a" # Best practices
                "a2e0ebc4-9fbf-4316-bc12-f4a458c3782d" # guides
                "e2f60e38-805b-4307-81bb-9192be4c7820" # Best Practices - Metrics & ROI
                "5a888a5e-0f6d-487d-a426-877fdc9ce6de" # Best Practices - Insights
                "4a190571-c5a0-4e09-ad3b-ddfe66379c3a" # Best Practices - Engage
                "3ba54205-09a8-4ce2-8d12-866e371555e1" # Best Practices - Grow
                "5793e7d1-0516-48d9-a00d-cfbc05f1084d" # Best Practices - Launch
                "2942ebd3-0be6-44cf-afe8-1ce09a18f2c8" # PLG
                "53a72a1c-4df4-4a20-861e-be6802016af1" # community ops
              ]
            }
          }
        }
      }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
  }
`;

export const TopicCommunity = () => {
  const { posts } = useStaticQuery(query) || {};

  return posts ? (
    <CardGrid
      maxColumns={1}
      nodes={mapEdgesToNodes(posts)}
      browseMoreHref="/blog/archive"
      hideType={true}
      showSubTags={true}
    />
  ) : null;
};
