import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { CardContext } from "../../card/context";
import { Card } from "../../card/card";
import { Button } from "../../button";

// Tag structure doc
// https://www.notion.so/commonroomnyc/Blog-categories-13801b2167114a6ca0df484bf9a6038e

// community-posts-teaser.js
// topic-community.js

const query = graphql`
  query CommunityInsightsQuery {
    postsImg: allSanityPost(
      limit: 2
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        isPublished: { eq: true }
        hideMainMedia: { ne: true }
        categories: {
          elemMatch: {
            _id: {
              in: [
                "fec871bb-5782-40da-9649-d5dbae4edc63" # insights
              ]
            }
          }
        }
      }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
    postsText: allSanityPost(
      limit: 1
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        isPublished: { eq: true }
        hideMainMedia: { eq: true }
        categories: {
          elemMatch: {
            _id: {
              in: [
                "fec871bb-5782-40da-9649-d5dbae4edc63" # insights
              ]
            }
          }
        }
      }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
    videos: allGoogleSpreadsheetWwWebsiteContentVideos(
      limit: 2
      sort: { fields: [publishDate], order: DESC }
      filter: { status: { eq: "published" }, product: { ne: "1" } }
    ) {
      edges {
        node {
          title
          videoId
          playlist
          slug
          community
          devRel
          gtm
          publishDate
          description
          product
        }
      }
    }
  }
`;

export const CommunityInsights = () => {
  const { postsText, postsImg, videos } = useStaticQuery(query) || {};
  const postListText = mapEdgesToNodes(postsText);
  const postListImg = mapEdgesToNodes(postsImg);

  const postListVideo = mapEdgesToNodes(videos).map((v) => {
    return {
      ...v,
      imageUrl: `https://img.youtube.com/vi/${v.videoId}/maxresdefault.jpg`,
      summary: v.description,
      cta: "Watch video",
      ctaLink: v.slug,
    };
  });

  return postListImg && postListText ? (
    <div className="grid grid-cols-1 sm:grid-cols-1/3-2/3 gap-1">
      <div className="mb-12 md:mb-0">
        <div className="grid gap-1">
          {postListVideo.map((p, i) => (
            <CardContext.Provider
              value={{ ...p, hideLabels: true, showSummary: true }}
              key={i}
            >
              <Card />
            </CardContext.Provider>
          ))}
        </div>
        <Button
          text="More videos"
          href="/topics/community"
          bgColor="bg-black"
          className="mt-4"
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
        <CardContext.Provider
          value={{ ...postListImg[0], hideLabels: true, jumboLayout: true }}
        >
          <Card className="sm:col-span-2 min-h-24" />
        </CardContext.Provider>
        <div>
          <CardContext.Provider
            value={{ ...postListText[0], hideLabels: true }}
          >
            <Card />
          </CardContext.Provider>
        </div>
        <div>
          <CardContext.Provider value={{ ...postListImg[1], hideLabels: true }}>
            <Card />
          </CardContext.Provider>
          <Button
            text="More from blog"
            href="/topics/community"
            bgColor="bg-black"
            className="mt-4 inline-block sm:hidden"
          />
        </div>
        <div>
          <Button
            text="More from blog"
            href="/topics/community"
            bgColor="bg-black"
            className="mt-4 hidden sm:inline-block"
          />
        </div>
      </div>
    </div>
  ) : null;
};
