import React from "react";
import { cn } from "../lib/helpers";
import { InfoIcon } from "./sections/snowflake/pricing-plans";
import PortableText from "./portableText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTowerCell,
  faCircleQuestion,
  faSquarePen,
  faLightbulb,
  faWarning,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

import * as styles from "./global/global.module.css";

const ICON_SIZE = "lg";

const CALLOUT_STYLE_MAP = new Map([
  [
    "warning",
    {
      color: "orange-800",
      bgColor: "rgba(243, 122, 9, 0.05)",
      borderColor: "rgba(243, 122, 9, 0.3)",
      icon: <FontAwesomeIcon icon={faTowerCell} size={ICON_SIZE} />,
    },
  ],
  [
    "danger",
    {
      color: "red",
      bgColor: "rgba(236, 75, 41, 0.05)",
      borderColor: "rgba(236, 75, 41, 0.3)",
      icon: <FontAwesomeIcon icon={faWarning} size={ICON_SIZE} />,
    },
  ],
  [
    "success",
    {
      color: "green-100",
      bgColor: "rgba(22, 163, 74, 0.05)",
      borderColor: "rgba(22, 163, 74, 0.3)",
      icon: <FontAwesomeIcon icon={faLightbulb} size={ICON_SIZE} />,
    },
  ],
  [
    "info",
    {
      color: "highlight-default",
      bgColor: "rgba(236, 239, 253)",
      borderColor: "rgba(63, 100, 239, 0.3)",
      icon: <FontAwesomeIcon icon={faCircleInfo} size={ICON_SIZE} />,
    },
  ],
  [
    "question",
    {
      color: "limeDark",
      bgStyle: styles.gradientEmerald,
      borderColor: "rgba(25, 170, 8, 0.3)",
      icon: <FontAwesomeIcon icon={faCircleQuestion} size={ICON_SIZE} />,
    },
  ],
  [
    "template",
    {
      color: "link",
      bgStyle: styles.gradientLavender,
      borderColor: "rgba(114, 62, 199, 0.3)",
      icon: <FontAwesomeIcon icon={faSquarePen} size={ICON_SIZE} />,
    },
  ],
]);

export const Callout = ({ node, children }) => {
  const style = CALLOUT_STYLE_MAP.get(node?.style || "info");
  const isInline = node?._type === "inlineInfoBox";

  return node ? (
    <span
      className={cn(
        "py-3 rounded-lg mb-4 items-start",
        !node?.header ? "flex" : "block",
        style?.bgStyle || "bg-dark-5",
        // !node?.header ? "border-l-4" : "border",
        // `border-${style?.color || "dark-20"}`,
        isInline ? "mt-4" : null
      )}
      style={{
        background: !style?.bgStyle ? style.bgColor : null,
        boxShadow: `0px 0px 0px 1px ${style.borderColor || "rgba(0,0,0,0.3)"}`,
      }}
    >
      {node?.header ? (
        <span
          className={cn(
            "flex items-center px-4 font-bold",
            node.body ? "pb-4 mb-4 border-b" : null,
            `text-${style?.color || "secondary"}`
          )}
          style={{ borderColor: style.borderColor || "rgba(0,0,0,0.3)" }}
        >
          <span className={cn("inline-block mr-3")}>{style?.icon}</span>
          {node?.header}
        </span>
      ) : (
        <div
          className={cn(
            "ml-4 mt-1",
            `text-${style?.color ? style.color : "secondary"}`
          )}
        >
          {style?.icon || <InfoIcon />}
        </div>
      )}
      <PortableText
        blocks={node?.body}
        className={cn(
          "px-4",
          !node.header ? `py-1 leading-normal text-${style?.color}` : null,
          styles.calloutLinks
        )}
      />
    </span>
  ) : null;
};
