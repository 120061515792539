import React, { useContext, useEffect, useState } from "react";
import { cn, mapEdgesToNodes } from "../../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import PortableText from "../../portableText";
import { SectionHeaders } from "../wrapper";
import { ImageBuilder } from "../../global/image-builder";
import { SiteContext } from "../../global/site-context";
import { pageDocPath } from "../../../../helpers";

// @todo change link ternary to status (isPublished) rather than _rawBody length

const LOGO_IMAGE_HEIGHT = 32;

const PLAN_FILTERS = [
  {
    label: "Free",
    slug: "essentials",
  },
  {
    label: "Starter",
    slug: "starter",
    action: "start",
  },
  {
    label: "Team",
    slug: "team",
  },
  {
    label: "Enterprise",
    slug: null,
  },
];

export const CATEGORIES = [
  {
    label: "Social & community",
    types: ["social", "code", "community"],
  },
  {
    label: "Business",
    types: ["gtm"],
  },
  {
    label: "Product",
    types: ["product"],
  },
  {
    label: "Custom",
    types: ["custom"],
  },
];

export const Integrations = ({ section }) => {
  const [integrations, setIntegrations] = useState([]);
  const [summary, setSummary] = useState([]);
  const [tag, setTag] = useState(null);

  const { docs, pricing } =
    useStaticQuery(graphql`
      query IntegrationsSectionQuery {
        docs: allSanityIntegration(
          sort: { fields: [company], order: ASC }
          filter: { status: { in: ["published", "hidden"] } }
        ) {
          edges {
            node {
              _type
              _id
              status
              company
              _rawExcerpt
              category
              plans
              isFeatured
              mainImage {
                ...SanityImageSimple
              }
              orderRank
              purchase
              purchaseStarter
              purchaseEnterprise
              _rawBody(resolveReferences: { maxDepth: 12 })
              partner
              sourceType
              docRef {
                _id
                _type
                slug {
                  current
                }
                menu
                menuParent
              }
            }
          }
        }
        pricing: sanityPricingWrapper(_id: { eq: "PricingTiers" }) {
          panels {
            title
            action
            _rawIntegrationsSummary
          }
        }
      }
    `) || {};

  const docList = docs ? mapEdgesToNodes(docs) : [];

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    setTag(urlParams.get("tag"));
  }, []);

  useEffect(() => {
    const filteredList = tag
      ? docList.filter(
          (doc) =>
            (doc.plans && doc.plans.find((t) => t === tag)) ||
            (tag === "team" && doc.purchase) ||
            (tag === "starter" && doc.purchaseStarter) ||
            (tag === "enterprise" && doc.purchaseEnterprise)
        )
      : docList;
    setIntegrations(filteredList);

    // @todo fix this config mismatch
    const planIndex = pricing.panels.findIndex(
      (panel) =>
        panel.action === tag ||
        (panel.action === "start" && tag === "starter") ||
        (panel.action === "enterprise" && tag === null)
    );

    setSummary(
      pricing.panels[planIndex === -1 ? 0 : planIndex]._rawIntegrationsSummary
    );
  }, [tag]);

  return (
    <>
      <SectionHeaders {...{ ...section, ...{ _rawSummary: [] } }} />

      <Filters tag={tag} section={section} setTag={setTag} />

      {pricing.panels && (
        <div className="text-secondary text-center text-sm md:text-base mx-auto mt-6">
          <PortableText blocks={summary} />
        </div>
      )}

      <div className="mt-10">
        {CATEGORIES.map((category) => {
          const list = integrations
            .filter(
              (doc) => doc.sourceType && category.types.includes(doc.sourceType)
            )
            // handle null and false values
            .sort((a, b) => b.isFeatured - a.isFeatured);

          return (
            <>
              {list.length > 0 && (
                <h5 className="font-semibold text-xl mb-4 mt-10 ml-2">
                  {category.label}
                </h5>
              )}
              <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                {list.map((doc) => (
                  <CardIntegration doc={doc} key={doc._id} tag={tag} />
                ))}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

const Filters = ({ tag, setTag }) => (
  <div className="flex justify-center mb-4 font-medium">
    {PLAN_FILTERS.map((filter) => (
      <a
        href={`?tag=${filter.slug || ""}`}
        className={cn(
          "py-2 px-3 rounded-lg inline-block mx-2 flex items-center",
          tag === filter.slug
            ? "bg-black text-white"
            : "bg-dark-5 hover:bg-dark-10"
        )}
        key={filter.slug}
        onClick={(e) => {
          e.preventDefault();
          setTag(filter.slug);
          // @todo history push state
        }}
      >
        {filter.label}
      </a>
    ))}
  </div>
);

export const CardIntegration = ({ doc, tag }) => {
  const site = useContext(SiteContext);
  const linked = site.isActive("linkIntegrations") && doc?.docRef;

  return (
    <a
      href={linked ? pageDocPath(doc.docRef) : null}
      className={cn(
        "relative rounded-2xl p-3 sm:p-6 md:p-3 lg:p-6 bg-white border border-dark-10",
        linked
          ? "hover:shadow-md scale-100 hover:scale-105 transform transition-all duration-200 ease-in-out"
          : null
      )}
    >
      <CardContent doc={doc} tag={tag} />
    </a>
  );
};

const CardContent = ({ doc, tag }) => (
  <div className="text-xs">
    <div className="flex sm:block">
      <div className="mr-2 md:mr-4 pt-1 sm:mb-3">
        <CompanyLogo image={doc.mainImage} alt={`${doc.company} logo`} />
      </div>
      <div className="mb-2">
        <p className="text-dark-50 hidden sm:block">{doc.category}</p>
        <h4 className="font-semibold text-xl">{doc.company}</h4>
        <p className="text-dark-50 sm:hidden">{doc.category}</p>
        {doc.partner && (
          <p className="my-3 px-2 py-1 rounded-md bg-dark-5 text-xs inline-block">
            Data via partner
          </p>
        )}
        {((doc.purchase && tag === "team") ||
          (doc.purchaseStarter && tag === "starter") ||
          (doc.purchaseEnterprise && !tag)) && (
          <p className="my-2 px-2 py-1 rounded-md bg-dark-5 text-xs inline-block">
            Add-on
          </p>
        )}
      </div>
    </div>
    <div className="text-dark-80 leading-tight">
      <PortableText blocks={doc._rawExcerpt} />
    </div>
  </div>
);

const CompanyLogo = ({ image, alt }) =>
  image ? (
    <ImageBuilder
      image={image}
      height={LOGO_IMAGE_HEIGHT}
      width={Math.round(
        LOGO_IMAGE_HEIGHT * image.asset.metadata.dimensions.aspectRatio
      )}
      quality={100}
      style={{
        maxHeight: LOGO_IMAGE_HEIGHT,
        maxWidth: LOGO_IMAGE_HEIGHT * 1.5,
      }}
      alt={alt || image.alt}
    />
  ) : null;
