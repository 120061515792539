// see helpers.js
export const DOCS_MENU = [
  {
    title: "Welcome to Common Room",
    id: "get-started",
    path: "/docs/get-started/",
    parent: false,
    expanded: true,
  },
  {
    title: "Core Concepts",
    id: "core-concepts",
    parent: "get-started",
  },
  {
    title: "Signals and Integrations",
    id: "signals",
    path: "/docs/signals/",
    parent: false,
  },
  {
    title: "Common Room Native Signals",
    id: "common-room-native-signals",
    parent: "signals",
  },
  {
    title: "Business Data",
    id: "business-integrations",
    parent: "signals",
  },
  // {
  //   title: "Okta",
  //   id: "okta",
  //   parent: "business-integrations",
  // },
  // {
  //   title: "Salesforce Managed Package",
  //   id: "salesforce-managed-package",
  //   parent: "business-integrations",
  // },
  {
    title: "Product Usage",
    id: "product-usage-integrations",
    parent: "signals",
  },
  // {
  //   title: "S3",
  //   id: "s3",
  //   parent: "product-usage-integrations",
  // },
  {
    title: "Community and Social",
    id: "community-and-social-integrations",
    parent: "signals",
  },
  // {
  //   title: "Discourse",
  //   id: "discourse",
  //   parent: "community-and-social-integrations",
  // },
  // {
  //   title: "Slack",
  //   id: "slack",
  //   parent: "community-and-social-integrations",
  // },
  // {
  //   title: "YouTube",
  //   id: "youtube",
  //   parent: "community-and-social-integrations",
  // },
  {
    title: "Custom",
    id: "custom-integrations",
    parent: "signals",
  },
  // {
  //   title: "Zapier / API",
  //   id: "zapier-api",
  //   parent: "custom-integrations",
  // },
  {
    title: "Using Common Room",
    id: "using-common-room",
    path: "/docs/using-common-room/",
    parent: false,
  },
  {
    title: "Segments",
    id: "segments",
    parent: "using-common-room",
  },
  // {
  //   title: "Segment Actions",
  //   id: "segment-actions",
  //   parent: "segments",
  // },
  // {
  //   title: "Segment management",
  //   id: "segment-management",
  //   parent: "segments",
  // },
  {
    title: "Workflows",
    id: "workflows",
    parent: "using-common-room",
  },
  {
    title: "Contacts",
    id: "contacts",
    parent: "using-common-room",
  },
  {
    title: "Activity",
    id: "activity",
    slug: "activity-page",
    parent: "using-common-room",
  },
  {
    title: "Organizations",
    id: "orgs",
    slug: "organizations-page",
    parent: "using-common-room",
  },
  {
    title: "Reporting",
    id: "reporting",
    slug: "reporting-page",
    parent: "using-common-room",
  },
  {
    title: "Website Visitor Tracking",
    id: "visitor-tracking",
    slug: "website-visitor-tracking",
    parent: "using-common-room",
  },
  {
    title: "Set Preferences",
    id: "set-preferences",
    path: "/docs/set-preferences/account/",
    parent: false,
  },
  {
    title: "Find Help",
    id: "help",
    parent: false,
    hideCard: true,
  },
];
