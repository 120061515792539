import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { cn, mapEdgesToNodes, toPlainText } from "../../../lib/helpers";
import { ImageBuilder } from "../../global/image-builder";
import { FeatureCompareCell } from "./pricing-comparison";
import { CATEGORIES } from "./integrations";

const query = graphql`
  query ComparisonIntegrationsQuery {
    docs: allSanityIntegration(
      sort: { fields: [company], order: ASC }
      filter: { status: { in: ["published", "hidden"] } }
    ) {
      edges {
        node {
          _type
          _id
          status
          company
          _rawExcerpt
          category
          isFeatured
          mainImage {
            ...SanityImageSimple
          }
          mainImageSolid {
            ...SanityImageSimple
          }
          # orderRank
          plans
          purchase
          purchaseStarter
          purchaseEnterprise
          sourceType
        }
      }
    }
  }
`;

export const ComparisonIntegrations = ({ section }) => {
  const data = useStaticQuery(query);
  const docs = mapEdgesToNodes(data.docs);

  return CATEGORIES.map((category) => {
    const list = docs.length
      ? docs
          .filter(
            (doc) => doc.sourceType && category.types.includes(doc.sourceType)
          )
          // handle null and false values
          .sort((a, b) => b.isFeatured - a.isFeatured)
      : null;

    return list.length > 0 ? (
      <div key={category.label}>
        <CompareRow panel={{ title: category.label, isHeaderRow: true }} />
        {list.map((d) => (
          <CompareRow panel={d} key={d._key} />
        ))}
      </div>
    ) : null;
  });
};

const CompareRow = ({ panel }) => (
  <div className={cn("grid grid-cols-6 bg-white odd:bg-light-40")}>
    <FeatureCompareCell
      isHeaderRow={panel.isHeaderRow}
      isTitleCell={true}
      message={
        !panel.isHeaderRow ? (
          <a
            href={panel.docsUrl}
            className={cn(
              "flex items-center",
              panel.docsUrl
                ? "transform hover:scale-105 transition-transform duration-200 ease-in-out"
                : null
            )}
          >
            <ImageBuilder
              image={panel.mainImageSolid || panel.mainImage}
              width={40}
              className="mr-2 rounded-md"
            />
            <div>
              <p>{panel.company}</p>
              <p className="block text-xs text-secondary font-normal">
                {panel.category}
              </p>
            </div>
          </a>
        ) : (
          panel.title
        )
      }
    />
    <FeatureCompareCell
      isHeaderRow={panel.isHeaderRow}
      message={panel.isHeaderRow ? "Free" : null}
      isChecked={panel?.plans?.includes("essentials")}
    />
    <FeatureCompareCell
      isHeaderRow={panel.isHeaderRow}
      message={
        panel.isHeaderRow ? "Starter" : panel.purchaseStarter ? "Add-on" : null
      }
      isChecked={
        !panel.purchaseStarter ? panel?.plans?.includes("starter") : null
      }
      checkColor="lemon"
    />
    <FeatureCompareCell
      isHeaderRow={panel.isHeaderRow}
      message={panel.isHeaderRow ? "Team" : panel.purchase ? "Add-on" : null}
      isChecked={!panel.purchase ? panel?.plans?.includes("team") : null}
      checkColor="mint"
    />
    <FeatureCompareCell
      isHeaderRow={panel.isHeaderRow}
      message={
        panel.isHeaderRow
          ? "Enterprise"
          : panel.purchaseEnterprise
          ? "Add-on"
          : null
      }
      isChecked={panel.purchaseEnterprise ? false : true}
      checkColor="purple-darker"
    />
  </div>
);
