import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { TextPassage } from "./careers-blocks";
import { Button } from "../../button";
import { CardContext } from "../../card/context";
import { Card } from "../../card/card";
import { SectionHeaders } from "../wrapper";
import { QuotePanels } from "../quote-panels";

const quotes = [
  {
    text: "Hey folks! Is there an ‘optimal’ time when communities should start thinking about analytics? Our team is just getting started.",
    image: "emoji-face-right.svg",
    color: "lavender",
    className: "h-16 w-14 mt-2",
  },
  {
    text: "It’s super important to always have a #random channel, you want folks to find your community to be a pleasant and engaging place to hang out, not just a ‘useful’ one.",
    image: "emoji-avocado.svg",
    color: "mint",
    className: "h-16 w-28",
  },
  {
    text: "Responsiveness is a key metric. And you have to consider where a community team sits within an org. Brand focuses on growing the audience, marketing on people entering the funnel, and support on answering questions.",
    image: "emoji-nessy.svg",
    color: "green-900",
    className: "h-16 w-16",
  },
];

const query = graphql`
  query CommunityVoicesQuery {
    docs: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        isPublished: { eq: true }
        categories: {
          elemMatch: {
            _id: {
              in: [
                "da38ee48-4ccb-4632-aed4-fae6aeb77b8c" # convos
                "f9701e5b-3434-4c8d-9280-52b2a1dfea87" # rise
              ]
            }
          }
        }
      }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
  }
`;

export const CommunityVoices = ({ section }) => {
  const { docs } = useStaticQuery(query) || {};
  const docsList = mapEdgesToNodes(docs);

  return docs ? (
    <>
      <div className="grid md:grid-cols-1/3-2/3 gap-1 mb-24">
        <div className="mr-12">
          <TextPassage
            header={section.header}
            blocks={["Hear from experts across the community."]}
          />
          <p className="mt-4 hidden sm:block">
            <Button
              href="/topics/community/spotlight"
              bgColor="bg-black"
              text="See all posts"
            />
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
          {docsList.map((p, i) => (
            <div className={i === 0 ? "sm:col-span-2" : ""} key={p.id}>
              <CardContext.Provider
                value={{
                  ...p,
                  showByline: true,
                  hideLabels: true,
                  showByline: false,
                  jumboLayout: i === 0,
                  hideFeatured: true,
                }}
              >
                <Card />
              </CardContext.Provider>
            </div>
          ))}
        </div>
        <p className="mt-4 block sm:hidden">
          <Button
            href="/topics/community/spotlight"
            bgColor="bg-black"
            text="See all posts"
          />
        </p>
      </div>
    </>
  ) : null;
};
