import React, { useContext, useEffect, useState } from "react";
import { pageDocPath } from "../../../../helpers";
import { cn } from "../../../lib/helpers";
import { ShowEmoji } from "../../emoji";
import { SiteContext } from "../../global/site-context";
import PortableText from "../../portableText";
import { includePromos } from "../../sticky-popup";
import { ArrowLeft } from "../../icon/arrow-left";

import * as styles from "./banner.module.css";
import * as globalStyles from "../../global/global.module.css";

const CLICK_PREFIX = "action.banner--";
export const BG_CLASSES = [
  "gradientEmerald",
  "gradientCopper",
  "gradientTurquoise",
  "gradientGold",
];

const getUrl = (b) =>
  b.bannerLink === "elsewhere"
    ? b.externalUrl
    : b._type === "event" || b.bannerLink === "resources"
    ? pageDocPath(b)
    : b.bannerLink
    ? `${b.bannerLink}/${b.slug.current}`
    : null;

const DEFAULT_BANNER = {
  _id: "default",
  _type: "banner",
  _rawBanner: [
    {
      _key: "default",
      _type: "block",
      children: [
        {
          _key: "default",
          _type: "span",
          marks: [],
          text: "Thanks for your curiousity.",
        },
      ],
      markDefs: [],
      style: "normal",
    },
  ],
  emoji: "lock",
  externalUrl: "https://www.commonroom.io/",
};

export const Banner = () => {
  const site = useContext(SiteContext);
  const [pastBannerCount, setBannerCount] = useState(0);
  const [banner, setBanner] = useState();

  useEffect(() => {
    if (!site.banners.length > 0) return;

    const unseenBanners = site.banners.filter((b) =>
      // avoid if already clicked
      site.metrics.analytics?.storage?.getItem(`${CLICK_PREFIX}${b._id}`)
        ? false
        : true
    );
    setBanner(unseenBanners?.[0] || null);
  }, [site.banners]);

  useEffect(() => {
    let i = 0;
    let found = 0;
    while (i < localStorage.length) {
      const val = localStorage.key(i);
      if (val.startsWith(CLICK_PREFIX)) {
        found++;
      }
      i++;
    }
    if (found) {
      setBannerCount(found);
    }
  }, []);

  return banner && includePromos(site) ? (
    <a
      href={getUrl(banner)}
      className="group"
      onClick={() => {
        site.metrics.logEvent({
          category: "button_click",
          action: "banner",
          label: getUrl(banner),
        });
        localStorage.setItem(`${CLICK_PREFIX}${banner._id}`, true);
      }}
    >
      <BannerContent
        isDarkBg={site.isDarkBg}
        banner={banner}
        pastBannerCount={pastBannerCount}
      />
    </a>
  ) : null;
};

const BannerContent = ({ banner, pastBannerCount }) => (
  <div
    className={cn(
      "relative z-100 flex justify-center items-center py-4 text-sm sm:text-base leading-snug font-medium",
      pastBannerCount
        ? cn(
            pastBannerCount < BG_CLASSES.length &&
              globalStyles.hasOwnProperty(BG_CLASSES[pastBannerCount - 1])
              ? globalStyles[BG_CLASSES[pastBannerCount - 1]]
              : "bg-lavender"
          )
        : cn("bg-black text-white", styles.bannerLight)
    )}
    id="promo-banner"
  >
    <ShowEmoji
      name={banner?.emoji}
      className="ml-4 mr-4 w-9 sm:w-7 transform transition-size scale-100 group-hover:scale-125"
    />
    <div className="group-hover:underline">
      <PortableText blocks={banner?._rawBanner} />
    </div>
    <span className="ml-2 mr-8 relative top-px flex items-center">
      <ArrowLeft size={16} rotate={180} />
    </span>
  </div>
);

/**
 * Return banner if appropriate for page and user.
 * Doc type include assets and events.
 *
 * @todo use path for banner.externalUrl
 */
export const getBanners = ({ doc, assets = [], analytics }) => {
  const path = doc ? pageDocPath(doc).replace(/^\/|\/$/g, "") : null;

  // skip landing pages and docs
  if (
    ["documentation", "integration", "redirect"].includes(doc._type) ||
    !path ||
    path.match(/\/hello\//)
  )
    return false;

  return [
    // unique because of concat
    ...new Set(
      assets
        .filter((b) => {
          // match by path, with wildcards
          if (
            b.paths.includes(path) ||
            (path === "" && b.paths.includes("/"))
          ) {
            return true;
          }

          // avoid matching root path with wildcard
          return b.paths.find((p) =>
            p.match(/.*\*$/) && p !== `${path}/*`
              ? path.match(new RegExp(p))
              : p === path || (path === "" && b.paths.includes("/"))
          );
        })
        .concat(
          // match by tags
          doc.categories
            ? assets.filter((a) =>
                doc.categories.some((c) =>
                  a.categories.find((ac) => c._id === ac._id)
                )
                  ? a
                  : null
              )
            : []
        )
        .filter(
          (banner) =>
            // avoid self-promotion
            banner._id !== doc._id || banner.externalUrl !== pageDocPath(doc)
          // avoid if already clicked
          // analytics?.storage?.getItem(`${CLICK_PREFIX}${banner._id}`)
          //   ? true
          //   : false
        )
    ),
    // just return one
  ];
};
