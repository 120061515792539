import React, { useContext } from "react";
import { mapEdgesToNodes } from "../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { CardGrid } from "../card/card-grid";
import { SiteContext } from "../global/site-context";
import { MAX_SUGGESTED_POSTS, suggest } from "../../lib/suggestions";
import { pageDocPath } from "../../../helpers";

const query = graphql`
  query ResourcesSuggestionsQuery {
    # posts: allSanityPost(
    #   limit: 2
    #   sort: { fields: [publishedAt], order: DESC }
    #   filter: {
    #     slug: { current: { ne: null } }
    #     publishedAt: { ne: null }
    #     status: { eq: "published" }
    #     categories: {
    #       elemMatch: {
    #         _id: {
    #           in: [
    #             "086bd107-8028-4911-8060-ba5a88f93d5a" # product
    #           ]
    #         }
    #       }
    #     }
    #   }
    # ) {
    #   edges {
    #     node {
    #       ...cardNode
    #     }
    #   }
    # }
    # videoPosts: allSanityPost(
    #   limit: 1
    #   sort: { fields: [publishedAt], order: DESC }
    #   filter: {
    #     slug: { current: { ne: null } }
    #     publishedAt: { ne: null }
    #     isPublished: { eq: true }
    #     mainVideo: { url: { ne: null } }
    #   }
    # ) {
    #   edges {
    #     node {
    #       ...cardNode
    #     }
    #   }
    # }
    # assets: allSanityAsset(
    #   limit: 2
    #   sort: { fields: [_createdAt], order: DESC }
    #   filter: { slug: { current: { ne: null } }, status: { eq: "published" } }
    # ) {
    #   edges {
    #     node {
    #       ...cardNodeAsset
    #     }
    #   }
    # }
    # pages: allSanityPage(
    #   limit: 2
    #   sort: { fields: [_createdAt], order: DESC }
    #   filter: {
    #     slug: { current: { ne: null } }
    #     status: { eq: "published" }
    #     contentType: { nin: ["landing", "solution", null] }
    #   }
    # ) {
    #   edges {
    #     node {
    #       ...cardNodePage
    #     }
    #   }
    # }
    playbooks: allSanityPlaybook(
      limit: 6
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        # keep these on two lines, linter breaks the query
        slug: { current: { ne: null } }
        status: { eq: "published" }
      }
    ) {
      edges {
        node {
          ...cardNodePlaybook
        }
      }
    }
    videos: allGoogleSpreadsheetWwWebsiteContentVideos(
      sort: { fields: [publishDate], order: DESC }
      filter: { status: { eq: "published" }, product: { eq: "1" } }
      limit: 2
    ) {
      edges {
        node {
          title
          videoId
          playlist
          slug
          publishDate
        }
      }
    }
  }
`;

export const ResourcesSuggestions = ({ exclude, maxColumns, section }) => {
  const data = useStaticQuery(query) || {};
  // const videoPosts =
  //   data && data.videoPosts && mapEdgesToNodes(data.videoPosts);
  // const assets = data && data.assets && mapEdgesToNodes(data.assets);
  // const videos = data && data.videos && mapEdgesToNodes(data.videos);
  // const pages = data && data.pages && mapEdgesToNodes(data.pages);
  // const topics = data && data.topics && mapEdgesToNodes(data.topics);
  // const posts = data && data.posts && mapEdgesToNodes(data.posts);
  const playbooks = data && data.playbooks && mapEdgesToNodes(data.playbooks);

  const site = useContext(SiteContext);
  const excludeId = exclude || site?.doc?._id || 0;

  const suggestions = site.doc?.relatedPosts?.length > 0 ? suggest(site) : null;

  const unique = [
    ...new Map(
      []
        // .concat(
        //   videoPosts.map((a) => {
        //     return { ...a, isVideo: true };
        //   })
        // )
        .concat(
          playbooks.map((p) => {
            return { ...p, publishedAt: p._createdAt };
          })
        )
        // .concat(
        //   assets.map((a) => {
        //     return { ...a, publishedAt: a._createdAt };
        //   })
        // )
        // .concat(
        //   pages.map((a) => {
        //     return { ...a, publishedAt: a._createdAt };
        //   })
        // )
        // .concat(posts)
        // .concat(
        //   videos.map((v) => {
        //     return {
        //       ...v,
        //       imageUrl: `https://img.youtube.com/vi/${v.videoId}/maxresdefault.jpg`,
        //       url: pageDocPath({ ...v, _type: "video" }),
        //       _type: "video",
        //       isVideo: true,
        //       publishedAt: v.publishDate,
        //     };
        //   })
        // )
        // .concat(topics)
        .map((p) => [p.id || p.url, p])
    ).values(),
  ]
    .sort((a, b) => (a.publishedAt < b.publishedAt ? 1 : -1))
    .filter((d) => d?._id !== excludeId && d?.videoId !== excludeId);

  return (
    <>
      {suggestions ? (
        <CardGrid
          maxColumns={maxColumns || 2}
          nodes={suggestions.slice(0, section?.cards || MAX_SUGGESTED_POSTS)}
          panels={true}
          browseMoreHref="/resources/"
          browseMoreText="See all resources"
          makeThumbnail={true}
          hideLabels={true}
          hideDate={true}
          showSummary={true}
          hideTitles={true}
          showSubTags={true}
          showExtended={true}
          attribution={{ action: "suggestion" }}
        />
      ) : unique.length ? (
        <CardGrid
          maxColumns={maxColumns || 3}
          nodes={unique.slice(0, section?.cards || MAX_SUGGESTED_POSTS)}
          // panels={true}
          browseMoreHref="/resources/"
          browseMoreText="See all resources"
          // makeThumbnail={true}
          // hideTitles={true}
          hideDate={true}
          showSummary={true}
          hideLabels={true}
          // showSubTags={true}
          // showExtended={true}
          // hideType={true}
          attribution={{ action: "suggestion", label: "resources" }}
          minimal={true}
          cta="See playbook"
        />
      ) : null}
    </>
  );
};
