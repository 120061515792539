import React, { useState } from "react";
import { cn } from "../../../lib/helpers";
import { Emoji } from "../../emoji";
import { Subscribe } from "../subscribe";

import * as columnStyle from "../column-content.module.css";
import * as dialogStyle from "../../global/dialog.module.css";

const cards = [
  {
    header: "Be Heard",
    items: [
      {
        icon: "📺",
        text: "Join our community channels",
        url: "/uncommon/channels",
        // highlightSubscribe: true,
      },
      {
        icon: "📊",
        url: "https://twitter.com/CommonRoomHQ/status/1409583368400871427",
        text: "Take a Twitter poll",
      },
      {
        icon: "💌",
        email: "mailto:uncommon@commonroom.io",
        text: "Write to us",
      },
    ],
  },
  {
    header: "Be Supported",
    items: [
      { icon: "📆", url: "/uncommon/events", text: "Live events" },
      {
        icon: "🌱",
        url: "/uncommon/#support",
        text: "The Uncommon Support Fund",
      },
      {
        icon: "📚",
        url: "https://www.youtube.com/playlist?list=PLKDS0si7azFmSHNJvlQMg2KEiMmfo6qXU",
        text: "Free community courses",
      },
    ],
  },

  {
    header: "Be Connected",
    items: [
      { icon: "📌", url: "/uncommon/jobs", text: "Job board" },
      {
        icon: "📬",
        url: "/uncommon/newsletters",
        text: "Free monthly newsletter",
      },
      { icon: "🔜", url: "", text: "Community Advocacy Board" },
      { icon: "🔜", url: "", text: "Events & mixers" },
    ],
  },
];

export const UncommonSubscribeCards = () => {
  const [fadeTrigger, setFadeTrigger] = useState(false);

  return (
    <>
      {fadeTrigger && <div className={dialogStyle.successWrapper}></div>}
      <div className="z-100 relative" id="subscribe-widget">
        <Subscribe
          section={{ attribution: { campaignType: "uncommon-hero", website_attribution: "uncommon-hero", } }}
        />
      </div>
      <div className="p-12"></div>
      <div
        className={cn(
          "grid gap-6 text-sm items-start",
          `sm:grid-cols-${cards.length}`,
          columnStyle.cards
        )}
      >
        {cards.map((column, i) => (
          <div className="p-4 pb-10 rounded-lg" key={i}>
            <h3 className="font-black uppercase mb-4">{column.header}</h3>
            <ul>
              {column.items &&
                column.items.map((item, index) => (
                  <li className="mb-2" key={index}>
                    <ItemComponent
                      item={item}
                      setFadeTrigger={setFadeTrigger}
                    />
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};

const ItemComponent = ({ item, setFadeTrigger }) => {
  const linkClasses = "block transform transition-size hover:scale-105";

  if (item.url) {
    return (
      <a href={item.url} className={cn(linkClasses, "text-link")}>
        <ItemContent item={item} />
      </a>
    );
  } else if (item.email) {
    return (
      <a href={item.email} className={cn(linkClasses, "text-link")}>
        <ItemContent item={item} />
      </a>
    );
  } else if (item.highlightSubscribe) {
    return (
      <a
        href="#subscribe-widget"
        onClick={() => setFadeTrigger(true)}
        className={linkClasses}
      >
        <ItemContent item={item} />
      </a>
    );
  } else {
    return (
      <p className={cn(linkClasses, "text-gray-400")}>
        <ItemContent item={item} />
      </p>
    );
  }
};

const ItemContent = ({ item }) => (
  <>
    <span className="text-lg mr-2">
      <Emoji symbol={item.icon} label={item.text} />
    </span>
    {item.text}
  </>
);
