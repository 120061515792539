import React, {
  useContext,
  useCallback,
  useState,
  useLayoutEffect,
} from "react";
import { Footer } from "./footer";
import { Gdpr, GDPR_COOKIE } from "./gdpr";
import { useInView } from "react-intersection-observer";
import "intersection-observer";
import { SiteContext } from "./site-context";
import Analytics from "analytics";
import googleTagManager from "@analytics/google-tag-manager";
import clientConfig from "../../../client-config";
import useMedia from "use-media";
import { pageDocPath } from "../../../helpers";
import { cn } from "../../lib/helpers";
import { Banner, getBanners } from "../sections/snowflake/banner";
import {
  clientAttribution,
  appendUtms,
  attributionCookie,
  COOKIE_NAME,
} from "../../lib/client-attribution";
import { useCookies } from "react-cookie";
import { getCookieConsentValue } from "react-cookie-consent";
import { StickyPopup } from "../sticky-popup";
import Container from "../global/container";
import { SearchUX } from "../sections/snowflake/search";
import { APP_AUTH_COOKIE } from "../../lib/score";
import * as tailwind from "../../../tailwind.config";

import * as globalStyles from "./global.module.css";
import * as styles from "./layout.module.css";
import { GlobalNav } from "./global-nav";

const isLiveProd = process.env.CONTEXT === "production";

const backgroundColorMap = new Map([
  ["darkGray", "bg-gray-900"],
  ["black", "bg-dark-85"],
  ["lightLavender", "bg-purple-ultralight"],
  ["gradientLavender", globalStyles.gradientLavender],
  ["purple", "bg-purple-deeper"],
]);

// note: missing/broken homepage causes an unrelated analytics-core.module error
const analytics = Analytics({
  app: "www-common-room",
  plugins: [
    googleTagManager({
      containerId: clientConfig.gtm.trackingId,
      enabled: false,
    }),
    // googleAnalytics({
    //   measurementIds: [clientConfig.ga.trackingId]
    // })
  ],
});

export const Layout = ({
  children,
  site,
  hideNav,
  doc = {},
  isHomepage,
  assets,
  location,
}) => {
  const [activeSection, setActiveSection] = useState("");
  const [_cookies, setCookie] = useCookies([COOKIE_NAME]);
  const { ref, inView } = useInView({ initialInView: true });

  const [isSearchActive, setSearchActive] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [query, setQuery] = useState("");

  const isBrowser = typeof window !== "undefined";

  const isActive = useCallback(
    (flag) => {
      return site.featureFlags && site.featureFlags.includes(flag);
    },
    [site]
  );

  const [isTracking, setIsTracking] = useState(
    getCookieConsentValue(GDPR_COOKIE) || !isActive("strictGdpr")
  );

  if ((isLiveProd || isActive("allowTrack")) && isTracking) {
    analytics.plugins.enable("google-tag-manager");
  }

  const logEvent = ({ label, category = "default", action = "log" }) => {
    analytics.track(category, {
      category,
      action,
      label,
      instanceName: "code-events",
    });
  };

  const siteContext = useContext(SiteContext);
  const token = useCallback(
    (s) => {
      const search = site.interfaceTokens.find((t) => t.label === s);
      return search
        ? search.value
        : siteContext.uiTokens.has(s)
        ? siteContext.uiTokens.get(s)
        : "Missing UI String";
    },
    [site, siteContext]
  );

  // change to useCallback or put in useEffect with state
  const [cookies, _setCookie] = useCookies([APP_AUTH_COOKIE]);
  const isPopupDemo =
    isActive("popupDemo") &&
    siteContext.calendly &&
    (!isActive("popupAuthExclude") || !cookies.hasOwnProperty("cr-token"));

  const isLandingPage =
    doc?.contentType === "landing" ||
    ["company", "prospect"].includes(doc?._type) ||
    location?.pathname === "/hello";

  const isBlog = !!pageDocPath(doc).match(
    /^\/blog\/*$|\/blog\/archive\/*$|^\/topics/
  );

  const isDarkBg = ["darkGray", "black", "purple"].includes(
    doc?.backgroundColor
  );

  const background = backgroundColorMap.get(doc.backgroundColor);

  const isHeaderPage =
    doc?.sections?.[0]?.name === "header" ||
    ["event", "category", "signal"].includes(doc?._type);

  const domain = isBrowser
    ? window.location.hostname.replace(/^www\./i, "")
    : "commonroom.io";

  useLayoutEffect(() => {
    // UTM query param handling and storage
    clientAttribution({
      isActive,
      storage: analytics.storage,
      referrer: document.referrer,
      callback: (urlParams, storage) => {
        // add params to links to contact and signup
        appendUtms({
          dom: document,
          doc,
          urlParams,
          storage: analytics.storage,
        });
        // regenerate attribution cookie, delay for localStorage
        if (isBrowser) {
          setTimeout(() => {
            setCookie(COOKIE_NAME, attributionCookie(storage), {
              path: "/",
              domain: `.${domain}`,
            });
          }, 0);
        }
      },
    });
  }, []);

  const [banners] = useState(getBanners({ doc, assets, analytics }));

  return (
    <SiteContext.Provider
      value={{
        ...site,
        calendly: "https://calendly.com/florin-qfim/30min",
        banners,
        isPageTop: inView,
        activeSection,
        setActiveSection,
        setIsTracking,
        hideNav: hideNav || isLandingPage,
        metrics: {
          analytics,
          logEvent,
          logClick: (action, label) => {
            logEvent({ label, category: "click", action });
          },
          logError: (label, obj) => {
            logEvent({ category: "error", label });
            console.error(label, obj || null);
          },
        },
        token,
        isActive,
        isAdmin: !!analytics.storage.getItem("show"),
        isSearchActive,
        setSearchActive,
        setQuery,
        query,
        hasSearched,
        setHasSearched,
        // @todo page context
        doc,
        isHomepage,
        isBlog,
        isNotMobile: useMedia({ minWidth: tailwind.theme.screens.md }),
        isLarge: useMedia({ minWidth: tailwind.theme.screens.xl }),
        isBrowser,
        isDarkBg,
        isHeaderPage,
        generateUnpublishedPages: process.env.GATSBY_GENERATE_UNPUBLISHED_PAGES,
        location,
        isLiveProd,
      }}
    >
      <div
        className={cn(
          "relative",
          background,
          doc?.backgroundColor === "purple" ? "overflow-hidden" : null
        )}
        style={{
          minHeight: "100vh",
        }}
      >
        {doc?.backgroundColor === "black" && (
          <Container maxWidth="max-w-screen-lg" className="relative">
            <div
              className={cn(
                "absolute top-0 right-0 bottom-0 left-0 h-144",
                globalStyles.radialPurple
              )}
            ></div>
          </Container>
        )}
        {!hideNav && <Banner />}
        <div style={{ height: 0 }} ref={ref}></div>
        <GlobalNav />
        <div
          className={cn(
            styles.root,
            doc?._type === "page" && !isHeaderPage
              ? banners.length > 0
                ? "pt-24 md:pt-30"
                : !isHomepage
                ? "pt-12 md:pt-14"
                : null
              : null
          )}
        >
          {children}
        </div>
        {isPopupDemo && <StickyPopup />}
        <Footer />
        {isSearchActive ? <SearchUX /> : null}
        <Gdpr />
      </div>
    </SiteContext.Provider>
  );
};
