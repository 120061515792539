import React from "react";

export const Logo = ({ color, className, iconOnly, style }) => {
  const fillColor = color || "currentColor";

  return iconOnly ? (
    <svg
      style={style}
      className={className}
      fill={fillColor}
      pointerEvents="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 359.7 357.8"
      alt="Common Room logo"
    >
      <path d="M329.6,143,215.7,29.2a50.7,50.7,0,0,0-71.7,0L30.1,143a51,51,0,0,0,0,71.8L144,328.6a50.9,50.9,0,0,0,71.7,0L329.6,214.8A51,51,0,0,0,329.6,143ZM225.7,224.8l-45.8,45.8L88.1,178.9l91.8-91.7L225.7,133l-45.8,45.9Z" />
    </svg>
  ) : (
    <svg
      className={className}
      viewBox="0 0 228 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      alt="Common Room logo"
    >
      <path
        d="M32.47 13.292L20.7079 1.52982C18.6682 -0.509939 15.3316 -0.509939 13.2919 1.52982L1.5298 13.292C-0.509935 15.3317 -0.509935 18.6683 1.5298 20.708L13.2919 32.4702C15.3316 34.5099 18.67 34.5099 20.7098 32.4702L32.4719 20.708C34.5097 18.6683 34.5097 15.3317 32.47 13.292ZM21.7401 21.7402L17.0008 26.4795L7.52237 17.0009L17.0008 7.52242L21.7401 12.2617L17.0008 17.0009L21.7401 21.7402Z"
        fill={fillColor}
      />
      <path
        d="M63.3741 19.208H59.2514C58.7606 21.848 56.7484 22.616 54.9324 22.616C52.3803 22.616 50.4417 20.648 50.4417 16.976C50.4417 13.424 52.3803 11.384 54.8834 11.384C56.8711 11.384 58.6625 12.416 59.1287 14.792H63.2514C62.6379 10.832 59.4968 8 55.0306 8C49.4846 8 46 11.48 46 16.976C46 22.544 49.3619 26 54.9079 26C59.3987 26 62.7361 23.24 63.3741 19.208Z"
        fill={fillColor}
      />
      <path
        d="M78.1441 19.184C78.1441 14.504 74.8067 12.392 71.1503 12.392C67.4939 12.392 64.1565 14.504 64.1565 19.184C64.1565 23.864 67.4939 26 71.1503 26C74.8067 26 78.1441 23.864 78.1441 19.184ZM68.2791 19.184C68.2791 16.448 69.5552 15.464 71.1503 15.464C72.7454 15.464 74.0214 16.448 74.0214 19.184C74.0214 21.896 72.7944 22.928 71.1503 22.928C69.5061 22.928 68.2791 21.896 68.2791 19.184Z"
        fill={fillColor}
      />
      <path
        d="M83.8133 25.688V18.968C83.8133 16.688 84.525 15.56 86.1691 15.56C88.1078 15.56 88.2059 17.072 88.2059 18.92V25.688H92.255V18.968C92.255 16.688 92.9666 15.56 94.6108 15.56C96.5494 15.56 96.6476 17.072 96.6476 18.92V25.688H100.697V17.936C100.697 14.432 99.6414 12.488 96.2549 12.488C94.3899 12.488 92.7458 13.232 91.7396 14.672C91.1262 13.256 89.9237 12.488 87.8133 12.488C85.8256 12.488 84.4023 13.52 83.8133 14.48V12.776H79.7643V25.688H83.8133Z"
        fill={fillColor}
      />
      <path
        d="M106.904 25.688V18.968C106.904 16.688 107.615 15.56 109.259 15.56C111.198 15.56 111.296 17.072 111.296 18.92V25.688H115.345V18.968C115.345 16.688 116.057 15.56 117.701 15.56C119.64 15.56 119.738 17.072 119.738 18.92V25.688H123.787V17.936C123.787 14.432 122.732 12.488 119.345 12.488C117.48 12.488 115.836 13.232 114.83 14.672C114.216 13.256 113.014 12.488 110.904 12.488C108.916 12.488 107.493 13.52 106.904 14.48V12.776H102.855V25.688H106.904Z"
        fill={fillColor}
      />
      <path
        d="M139.147 19.184C139.147 14.504 135.81 12.392 132.153 12.392C128.497 12.392 125.16 14.504 125.16 19.184C125.16 23.864 128.497 26 132.153 26C135.81 26 139.147 23.864 139.147 19.184ZM129.282 19.184C129.282 16.448 130.558 15.464 132.153 15.464C133.748 15.464 135.025 16.448 135.025 19.184C135.025 21.896 133.798 22.928 132.153 22.928C130.509 22.928 129.282 21.896 129.282 19.184Z"
        fill={fillColor}
      />
      <path
        d="M144.816 14.48V12.776H140.767V25.688H144.816V19.064C144.816 16.688 145.553 15.56 147.27 15.56C149.234 15.56 149.307 17.072 149.307 18.92V25.688H153.356V17.936C153.356 14.432 152.301 12.488 148.915 12.488C146.853 12.488 145.405 13.52 144.816 14.48Z"
        fill={fillColor}
      />
      <path
        d="M167.514 19.136L171.367 25.688H176.299L171.907 18.464C173.944 17.696 175.391 16.232 175.391 13.664C175.391 9.344 171.931 8.288 167.318 8.288H160.471V25.688H164.668V19.136H167.514ZM164.668 11.744H167.441C169.428 11.744 170.95 12.104 170.95 13.712C170.95 15.344 169.428 15.68 167.441 15.68H164.668V11.744Z"
        fill={fillColor}
      />
      <path
        d="M190.433 19.184C190.433 14.504 187.096 12.392 183.439 12.392C179.783 12.392 176.446 14.504 176.446 19.184C176.446 23.864 179.783 26 183.439 26C187.096 26 190.433 23.864 190.433 19.184ZM180.568 19.184C180.568 16.448 181.844 15.464 183.439 15.464C185.034 15.464 186.311 16.448 186.311 19.184C186.311 21.896 185.084 22.928 183.439 22.928C181.795 22.928 180.568 21.896 180.568 19.184Z"
        fill={fillColor}
      />
      <path
        d="M205.447 19.184C205.447 14.504 202.11 12.392 198.454 12.392C194.797 12.392 191.46 14.504 191.46 19.184C191.46 23.864 194.797 26 198.454 26C202.11 26 205.447 23.864 205.447 19.184ZM195.583 19.184C195.583 16.448 196.859 15.464 198.454 15.464C200.049 15.464 201.325 16.448 201.325 19.184C201.325 21.896 200.098 22.928 198.454 22.928C196.809 22.928 195.583 21.896 195.583 19.184Z"
        fill={fillColor}
      />
      <path
        d="M211.117 25.688V18.968C211.117 16.688 211.828 15.56 213.473 15.56C215.411 15.56 215.509 17.072 215.509 18.92V25.688H219.558V18.968C219.558 16.688 220.27 15.56 221.914 15.56C223.853 15.56 223.951 17.072 223.951 18.92V25.688H228V17.936C228 14.432 226.945 12.488 223.558 12.488C221.693 12.488 220.049 13.232 219.043 14.672C218.43 13.256 217.227 12.488 215.117 12.488C213.129 12.488 211.706 13.52 211.117 14.48V12.776H207.068V25.688H211.117Z"
        fill={fillColor}
      />
    </svg>
  );
};
