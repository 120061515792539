import React from "react";
import { cn } from "../../lib/helpers";

export const Divider = ({ className, padding }) => (
  <hr
    className={cn(
      "h-0.5 bg-dark-10",
      padding === "none" ? null : "my-8",
      className
    )}
  />
);
