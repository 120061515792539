import React from "react";
import PortableText from "../portableText";
import { cn, toPlainText } from "../../lib/helpers";
import { SectionHeaders } from "./wrapper";
import { ImageBuilder } from "../global/image-builder";

// @todo avoid overloading column and swtich them to FeaturedMedia section

export const FeaturedMedia = ({ section }) => {
  const isLeft = section.layout === "left";
  const isCentered = section.layout === "centered";
  const isMediaSmall = section.layout === "mediaSmall";
  const isTwoUp = section.layout === "twoUp";
  const mainImage = section.image || section.mainImage;

  return (
    <>
      <SectionHeaders header={section.header} layout={section.layout} />
      <div
        className={
          isCentered
            ? "text-center"
            : cn(
                "grid sm:grid-cols-2 gap-6 md:gap-12",
                isTwoUp
                  ? "md:grid-cols-3/5-2/5 md:gap-20"
                  : isLeft
                  ? "md:grid-cols-2/3-1/3"
                  : isMediaSmall
                  ? "md:grid-cols-2"
                  : "md:grid-cols-1/3-2/3"
              )
        }
      >
        {isCentered && section._rawSummary && (
          <PortableText
            blocks={section._rawSummary}
            className={cn(
              "mx-auto",
              toPlainText(section._rawSummary).length > 200
                ? "max-w-screen-md"
                : "max-w-screen-sm"
            )}
          />
        )}
        <div
          className={cn(
            isLeft
              ? ""
              : isTwoUp
              ? "order-2"
              : "sm:order-2 flex sm:justify-end",
            isMediaSmall ? "flex justify-end items-start" : null,
            isCentered ? "py-8 lg:pb-12 flex justify-center" : null
          )}
        >
          <div className="relative">
            {mainImage && (
              <>
                <ImageBuilder
                  image={mainImage}
                  alt={mainImage.alt}
                  width={isCentered ? 1024 : isMediaSmall ? 473 : 606}
                />
                {mainImage.caption && (
                  <figcaption className="text-secondary text-center text-sm mt-4 mb-6">
                    {mainImage.caption}
                  </figcaption>
                )}
              </>
            )}
            {isTwoUp && (
              <div className="hidden md:flex items-center absolute top-0 left-0 bottom-0 right-0">
                <ImageBuilder
                  src="/static/img/icons/long-arrow-right.svg"
                  className="relative -ml-14"
                />
              </div>
            )}
          </div>
        </div>

        {!isCentered && section._rawSummary && (
          <div
            className={cn(
              "flex flex-wrap sm:content-center items-center sm:h-full leading-tight",
              isLeft ? "sm:order-2" : isTwoUp ? "order-1" : "sm:order-1"
            )}
          >
            <PortableText blocks={section._rawSummary} />
          </div>
        )}
      </div>
    </>
  );
};

export const Image = ImageBuilder;
