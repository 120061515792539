import React from "react";

const PODCAST_URL =
  "https://open.spotify.com/embed/show/3jgMogRDZgT2tvRlUMVvxI?utm_source=generator";

export const Podcast = () => (
  <iframe
    style={{ borderRadius: "12px" }}
    src={PODCAST_URL}
    width="100%"
    height="232"
    frameBorder="0"
    allowFullScreen=""
    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
  ></iframe>
);
